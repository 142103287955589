<template>
  <div>
    <level-bar>{{pageName}}</level-bar>
    <!-- METRICS -->
    <div class="box">
      <nav class="level">
       <div class="level-item has-text-centered" v-for="header in headerBoxEntries" :key="header.name">
          <div>
            <p class="heading">{{header.label}}</p>
            <p class="title" v-tooltip="header.tooltipTxt" v-html="header.value"></p>
          </div>
        </div>
      </nav>
    </div>
    <div class="columns">
      <div class="column">
        <intelyt-card :cardOptions="cardOptions" :reportOptions="reportOptions">
          <template slot="header">{{itemName}} Details</template>
          <template slot="controls">
            <base-icon v-if="getSetPoints" name="information" @click="getSetPointDetails()" v-tooltip="`View ${messageDetails.title}`" />
          </template>
          <div>
            <intelyt-list :items="shipmentDetails" numColumns=1>
            </intelyt-list>
          </div>
        </intelyt-card>
        <intelyt-card maxHeight="250px" :scrolling="true" v-if="!$store.state.user.permissions.includes('hideAlerts')">
          <template slot="header">Notifications</template>
          <template slot="controls">
            <base-icon name="message_add" @click="openModal('createAlert')" v-tooltip="'Create Message'" v-if="showAlertMessage" />
          </template>
          <div class="tabs is-boxed">
            <ul class="noMarginleft">
              <li v-for="(tab, idx) in getNotificationTabs" :key="idx" :class="{'is-active': (activeTab === idx)}"><a @click="activateTab(idx)">{{tab.name}} <span :class="{ 'text-danger': tab.unAcknowledged }">({{tab.value.length}})</span> </a></li>
            </ul>
          </div>
          <div class="content">
            <div v-for="(tab, idx) in getNotificationTabs" :key="idx" :id="idx" class="content-tab" v-show="activeTab === idx">
              <div class="field-group">
                <div class="field">
                  <template v-for="msg in tab.value">
                    <intelyt-alert :alert=msg :hideId="true" :key="msg.entryId"></intelyt-alert>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </intelyt-card>
        <intelyt-card maxHeight="250px" :scrolling="true" v-if="shipment.shock && !$store.state.user.permissions.includes('hideShock')">
          <template slot="header">Shock Notices</template>
          <template slot="controls">
            <base-icon name="refresh" v-if="!isDisabled" @click="fetchAlerts()" />
          </template>
          <base-table
              :data="shockData"
              :columns="shockTableCols"
              :options="shockTableOptions"
              :isNarrow="true"
            >
            </base-table>
        </intelyt-card>
      </div>
      <div class="column">
        <div class="detail-page-slider">
          <base-slider v-on:sliderChange="updateRange" :disabled="isDisabled" :end="sliderEnd" :intervals="sliderInterval" :units="pageConfig.slider.scale"></base-slider>
        </div>
        <intelyt-card v-if="pageConfig.map">
          <template slot="header">Location</template>
          <template slot="controls">
            <span>
              <div class="control">
                <span class="field" style="font-weight:bold;">
                  <span  v-tooltip="'Show Cell Tower'">
                    <base-icon name="cell_tower" v-if="showCellTowerIcon" @click="showCellTower = !showCellTower" :style="{opacity: !showCellTower ? 0.6 : 1}"/>
                  </span>
                  <span  v-tooltip="'Zoom To Current Location'">
                    <base-icon name="location" @click="changeCurrentLocation()" />
                  </span>
                </span>
              </div>
            </span>
          </template>
          <intelyt-map
            v-if="shipment.loaded"
            :markers="getMapMarkers"
            :pathList="getPaths($route.query.id, sliderValue, activeMarkers, chartTimestamp, showDirection)"
            :mapWidth="mapWidthCalc"
            :polygons="polygons"
            :currentZoom="currentZoom"
            :zoomFixed="zoomFixed"
            :zoom="zoomValue"
            :customCenter="customCenter"
            :waypoints="cellAouList"
          ></intelyt-map>
          <intelyt-map-legend
            v-if="shipment.loaded && markers.length > 0"
            :markers="pageConfig.map.markers"
            :activeMarkers="activeMarkers"
          ></intelyt-map-legend>
        </intelyt-card>
        <intelyt-card v-if="showLocHistory">
          <template slot="header">Location History</template>
          <template slot="controls">
            <base-icon name="download_csv" v-show="getLocationData().length"  @click="exportLocData()" v-tooltip="'Download Location Data'" class="icon-padding"/>
          </template>
          <base-table
               :data="getLocationData()"
               :columns="locationColumns"
               :options="locationTableoptions">
          </base-table>
          <div class="copySection"><input type="text" id="locationCopy" /></div>
        </intelyt-card>
      </div>
      <div class="column">
        <intelyt-card maxHeight="350px" :scrolling="true" v-if="pageConfig.chimeNetwork && shipment.chimeCount > 0">
          <template slot="header">{{chimeTableHeaderText}}</template>
          <template slot="controls">
            <base-icon name="refresh" v-tooltip="'Refresh Chime Data'" v-if="showAclRefresh" @click="fetchChimeData()" />
            <base-icon v-if="networkCharts && networkCharts.length > 0" name="menu" @click="openModal('chimeNetworkDetail')" v-tooltip="'Chime Network Detail'" />
          </template>
          <div id="chimeTable">
            <base-table
              :data="chimeTableData"
              :columns="chimeTableCols"
              :options="{paginate: true, perPage: 5}"
              :isNarrow="true"
            >
            </base-table>
          </div>
        </intelyt-card>
        <intelyt-card v-if="shipment.loaded">
          <template slot="header">Historic {{itemName}} Conditions</template>
          <template v-if="pageCharts.length > 0">
            <div v-for="(echart, idx) in pageCharts" :key="idx">
              <base-chart
                :classname="echart.classname"
                :showLoadingIcon="false"
                :options='getChartData(`${echart.key}ChartOptions`)'
                :dblClickHandler="echart.ClickHandler">
              </base-chart>
            </div>
          </template>
        </intelyt-card>
      </div>
    </div>
    <!-- {{ editShpDefaults }} -->
    <add-edit-modal
      :title="addEditFormObj.title"
      :mode="'edit'"
      :formElements="addEditFormObj.formElements"
      :defaults="addEditFormObj.defaults"
      :isActive="modalIsActive.addEdit"
      v-on:close="closeModal('addEdit')"
      :callback="addEditFormObj.callback"
    >
    </add-edit-modal>
    <!-- <add-flight-modal
      :isActive="modalIsActive.addFlight"
      :guid="shipment.guid"
      v-on:close="closeModal('addFlight')"
    >
    </add-flight-modal> -->
    <chime-detail-modal
      :isActive="modalIsActive.chimeDetail"
      :guid="shipment.guid"
      :chimeCount="shipment.chimeCount"
      :deviceDetail="deviceDetail"
      :chartList="networkCharts"
      v-on:close="closeModal('chimeDetail')"
    >
    </chime-detail-modal>
    <chime-network-detail-modal
      :isActive="modalIsActive.chimeNetworkDetail"
      :guid="shipment.guid"
      :alertList="networkChartAlerts"
      :chartList="networkCharts"
      :chimeCount="shipment.chimeCount"
      v-on:close="closeModal('chimeNetworkDetail')"
    >
    </chime-network-detail-modal>
    <create-alert-modal
      :isActive="modalIsActive.createAlert"
      v-on:close="closeModal('createAlert')"
    >
    </create-alert-modal>
    <!-- Add Tempalte file // TemplateFIle manualDeleteNoCmd or ManualDeleteAsset or manualDeleteShipment-->
    <delete-shipment-modal
      :isActive="modalIsActive.deleteShipment"
      :guid="shipment.guid"
      v-on:close="closeModal('deleteShipment')"
    >
    </delete-shipment-modal>
    <report-config-modal
      :isActive="modalIsActive.reportConfig"
      :selectedReport="selectedReport"
      :externalControls="externalControls"
      v-on:generate="previewReport"
      v-on:close="closeModal('reportConfig')"
    >
    </report-config-modal>
    <report-results-modal
      :reportData="reportData"
      :reportSearch="false"
      :fileName="reportFileName"
      :isActive="modalIsActive.reportResults"
      v-on:close="closeModal('reportResults')"
    >
    </report-results-modal>
    <send-command-modal
      :messageObj="cmdModal.messageObj"
      :isActive="modalIsActive.sendCommand"
      :actionButtonText="'Confirm'"
      :commandArgs="{callbackFn: cmdModal.callback, params: cmdModal.params, event: cmdModal.event}"
      v-on:emitFunction="emitFunctionCallback"
      v-on:close="closeModal('sendCommand')"
    >
    </send-command-modal>
    <shipment-share-link-modal
      :isActive="modalIsActive.shipmentShareLink"
      :guid="shipment.guid"
      :title="'Shipment Link'"
      :description="shareModalDesc"
      v-on:close="closeModal('shipmentShareLink')"
    >
    </shipment-share-link-modal>
    <shock-modal
      :isActive="modalIsActive.shock"
      :alertObj="alertObj"
      :macId="this.shipment.macId"
      v-on:close="closeModal('shock')"
    >
    </shock-modal>
    <show-message-modal
      :isActive="modalIsActive.showMessage"
      :title="this.messageDetails.title"
      :message="this.messageDetails.message"
      :isHtml="true"
      v-on:close="closeModal('showMessage')"
    >
    </show-message-modal>
  </div>
</template>

<script>
// Componenet imports
import IntelytMapLegend from '@/components/IntelytMapLegend'
import BaseSlider from '@/components/BaseSlider'

// Vue/Vuex/library function imports
import router from '../router'
import moment from 'moment-timezone'
import { mapState, mapActions, mapGetters } from 'vuex'

// helper and formatter imports
import {astShpmntDataFormat, batteryIcon, batteryTooltip, capitalize, deviceLink, getProvisionerName, securityIcon, relativeTime, shortDate} from '@/store/formatters.js'
import {buildTempAndHumidChartOptions, exportAsCSV, generateIconSVG, getMapWidth, getShpmntDeviceType, isBetween, lookupProjectIdHelper, lookupProjectMsgHelper, getMessageObject, getACLCommands, getCommandList, getDateFormat, getUrl, isJSON, round} from '@/store/helpers.js'

import {chartOptionFactory, scatterChartOptionFactory} from '@/store/factories'

import { getQueryProvider } from '../store/providers'

// Mixins imports
import { modalWindowManager } from '@/store/mixins'

// Modal imports
import AddEditModal from '@/modals/AddEditModal'
// import AddFlightModal from '@/modals/AddFlightModal'
import ChimeNetworkDetailModal from '@/modals/ChimeNetworkDetailModal'
import ChimeDetailModal from '@/modals/ChimeDetailModal'
import CreateAlertModal from '@/modals/CreateAlertModal'
import DeleteShipmentModal from '@/modals/DeleteShipmentModal'
import ShipmentShareLinkModal from '@/modals/ShipmentShareLinkModal'
import ShockModal from '@/modals/ShockModal'
import ShowMessageModal from '@/modals/ShowMessageModal.vue'

export default {
  name: 'DetailPage',
  beforeRouteEnter (to, from, next) {
    next(vm => {
      // console.log('route', from, vm)
      // Store the previous route value in the data object to pass the redirect path to error page
      vm.prevRoute = from
    })
  },
  components: {
    AddEditModal,
    //AddFlightModal,
    BaseSlider,
    ChimeDetailModal,
    ChimeNetworkDetailModal,
    CreateAlertModal,
    DeleteShipmentModal,
    IntelytMapLegend,
    ShipmentShareLinkModal,
    ShockModal,
    ShowMessageModal
  },
  computed: {
    /**
     * batteryChartOptions - Prepare the chat options for the battery chart
     * The series will be constructed using the shipments tsData list
     * Returns the chart option object from the chartOptionFactory
     */
    batteryChartOptions: function () {
      // Set variable items for chart
      const title = 'Battery Level'
      const subtitle = '' // this.slider.value[0] + ' to ' + this.slider.value[1]
      let yAxis = []
      let vMapPieces = []
      let series = []
      vMapPieces = ['charge']
      yAxis = [
        {name: 'Charge', formatter: 'percent', ymax: 1, ymin: 0, splitLine: {show: false}},
        {name: 'Voltage', formatter: 'none', ymax: 4.2, ymin: 0, splitLine: {show: true, lineStyle: {type: 'dotted'}}}
      ]
      // Get series but filter out any values where charge or battery = 0 (or less).  These are bad records
      series = [
        {
          name: 'Charge',
          data: this.getShipmentTimeSeries(this.$route.query.id, this.sliderValue, 'charge').filter(c => c[1] > 0)
        }, {
          name: 'Voltage',
          yAxisIndex: 1,
          data: this.getShipmentTimeSeries(this.$route.query.id, this.sliderValue, 'battery').filter(v => v[1] > 0)
        }
      ]
      // Get chart object
      // legend bottom 5
      const chartConfig = this.$store.state.configuration.charts
      const tmz = this.$store.state.user.timezone ? this.$store.state.user.timezone : moment.tz.guess()
      const options = {subtitle, yAxis, vMapPieces, tooltip: 'datetime', colors: chartConfig.colors.lineChart, legend: {bottom: 5}}
      return chartOptionFactory(chartConfig, title, series, options, {}, tmz)
    },
    cardOptions: function () {
      // Detail card options from configuration
      const controls = this.pageConfig.controls
      const isAdmin = this.$store.state.user.roles.includes('Company Administrator')
      const isProvisioner = this.$store.state.user.roles.includes('Provisioner')
      // const hasChimes = this.chimeTableData.length > 0
      const showAlways = true // For default options and available in Detail card options
      const showDecomm = !this.shipment.decommDate && isProvisioner // If shipment is not decommissioned
      const showDelete = isAdmin // Available only for admin
      const showEdit = isAdmin || showDecomm // Available for admin or shipment not decommissioned
      // The following control the rebuild and build ACL commands. this reasonably approximates getActivation
      const rebuildTime = this.shipment.lastStageCmdTime ? moment.utc(this.shipment.lastStageCmdTime) : moment()
      const timeSinceRebuild = moment().diff(rebuildTime, 'minutes')
      const showRebuildAcl = this.shipment.shippingState == 2 && (timeSinceRebuild > 3 || !this.shipment.lastStageCmdTime)
      const showBuildAcl = this.shipment.shippingState == 2 && (timeSinceRebuild > 3 || !this.shipment.lastStageCmdTime)
      // const showRebuildAcl = (isProvisioner || isAdmin) && hasChimes // Available only for admin or provisioner && the shipment have chime records
      const showPublicLink = this.$store.state.company.publicAccess // Available, if company have public access
      // Control for the enable shock option - must have shock active on shipment and be provisioner or admin and be active (state = 2)
      const showShock = this.shipment.shock && (isProvisioner || isAdmin) && Number(this.shipment.shippingState) === 2
      // Enable/Disable shock option label
      const toggleShockLabel = this.shipment.shock === 'Enabled' ? 'Disable Shock' : 'Enable Shock'
      const showTagAssociation = this.shipment.shippingState > 1 && this.shipment.shippingState < 6 && this.shipment.configType === '3'
      // Get the command object from configuration
      const commands = this.$store.state.configuration.commands

      // Remove chime payload preparation
      const removeDevicePayload = {
        event: 'removeDevice',
        payload: {
          action: 'runActivity',
          template: this.$store.state.configuration.templateFiles.removeDevice,
          mac: this.shipment.macId,
          guid: this.shipment.guid,
          runByGUID: true
        }
      }

      // Send Shock Command Payload Preparation
      const toggleShockPayload = {
        event: this.shipment.shock === 'Enabled' ? 'disableShock' : 'enableShock',
        payload: {
          action: 'sendCommands',
          deviceIdList: [this.shipment.macId],
          commandList: this.shipment.shock === 'Enabled' ? commands.disableShock : commands.enableShock
        }
      }

      const mode = this.$route.query.mode
      const template = this.$store.state.configuration.pageOptions[`${mode}Detail`].decommTemplate || this.$store.state.configuration.templateFiles.manualDecommNoCmd
      // Send Decommission activity payload preparation
      const decommPayload = {
        event: 'decommAsset',
        payload: {
          action: 'runActivity',
          template,
          mac: this.shipment.macId,
          guid: this.shipment.guid,
          runByGUID: false
        }
      }

      /* Build payload for the Rebuild ACL function which calls an activity to update shipment record and send appropriate commands to the device to ask it to reset the current ACL. */
      const rebuildACLPayload = {
        event: 'rebuildAcl',
        eventEmit: {event: 'updateLastStageCmdTime'},
        payload: {
          action: 'runActivity',
          template: this.$store.state.configuration.templateFiles.rebuildACL,
          mac: this.shipment.macId,
          guid: this.shipment.guid,
          runByGUID: true
        }
      }

      /* Build payload for the Build ACL function which will build a complex command list to completely build the ACL.  The broadcast address is kept in place but the chime list can grow or shrink depending on what the current tag configuration is at the time these commands are sent.  It effectively builds a new ACL with the former broadcst address. */
      const buildACLPayload = this.getACLCommandsPayload()
      // All the available card option listed below. Only the options available in configuration and it's show condition is true will be returned as the output.
      const allCardOptions = {
        decommission: {icon: 'cancel', text: 'Decommission', optionArgs: {callbackFn: this.openSendCmdModal, params: decommPayload}, show: showDecomm},
        delete: {icon: 'trash', text: 'Delete', optionArgs: {callbackFn: this.openShipmentModals, params: 'deleteShipment'}, show: showDelete},
        edit: {icon: 'edit', text: 'Edit', optionArgs: {callbackFn: this.openShipmentModals, params: 'addEdit'}, show: showEdit},
        flightSearch: {icon: 'plane', text: 'Add Flight', optionArgs: {callbackFn: this.openShipmentModals, params: 'addFlight'}, show: showAlways},
        projectId: {icon: 'search', text: 'Lookup Project ID', optionArgs: {callbackFn: this.lookupProjectId}, show: showAlways},
        rebuildAcl: {icon: 'refresh', text: 'Rebuild ACL', optionArgs: {callbackFn: this.openSendCmdModal, params: rebuildACLPayload}, show: showRebuildAcl},
        buildAcl: {icon: 'refresh', text: 'Build ACL', optionArgs: {callbackFn: this.openSendCmdModal, params: buildACLPayload}, show: showBuildAcl},
        removeDevice: {icon: 'cancel', text: 'Remove Device', optionArgs: {callbackFn: this.openSendCmdModal, params: removeDevicePayload}, show: showAlways},
        showLink: {icon: 'link', text: 'Share Shipment Link', optionArgs: {callbackFn: this.openShipmentModals, params: 'shipmentShareLink'}, show: showPublicLink},
        tagAssociation: {icon: 'tag', text: 'Associate Tag', optionArgs: {callbackFn: this.openShipmentModals, params: 'associateTag'}, show: showTagAssociation},
        toggleShock: {icon: 'shock', text: toggleShockLabel, optionArgs: {callbackFn: this.openSendCmdModal, params: toggleShockPayload}, show: showShock}
      }
      // Loop the each config item. If the config is an object, then overwrite the text value from configuration
      // Else return the config based on the key value
      const mapFn = function (key) {
        if (typeof key === 'object') {
          let column = allCardOptions[key.name]
          column.text = key.text
          return column
        } else {
          return allCardOptions[key]
        }
      }
      // Get the cardoptions from the list and filter based on the show value
      const filteredOptions = controls.map(mapFn).filter(col => col.show)
      return Object.values(filteredOptions)
    },
    /**
     * Returns the timestamp from the chart value
     * When a data value clicked from the chart/map, the timestamp value will be stored in the state
     * It the chart click found in the state, it will returns the timestamp value
     * Else will return 0
     * Here the chart time stamp is used to highlight clicked route
     */
    chartTimestamp: function () {
      return this.chartClick.default ? this.chartClick.default.timestamp : 0
    },
    // Returns the chime table column configuration
    chimeTableCols () {
      // Returns the icon for the given input
      const getIcon = (icon, color, size) => {
        const iconObj = {
          name: icon,
          color: color,
          size: size
        }
        return `<span align="center">${generateIconSVG(this.$store.state, iconObj)}</span>`
      }
      const showDetailIcon = function (row) {
        return !row.devProps.parent || row.devProps.parent.length === 0
      }
      const showAssetLink = function (row) {
        return row.devProps.parent && row.devProps.parent.length > 0
      }
      const viewAsset = function (row) {
        window.location.assign(getUrl(`/detail?id=${row.devProps.parent}&mode=asset`))
      }
      // Control icon list for the chime list table
      const controlIcons = [
        {icon: 'list', callback: this.showChimeDetailModal, tooltip: 'Chime Detail', show: showDetailIcon},
        {icon: 'link', callback: viewAsset, tooltip: 'Chime Detail', show: showAssetLink}
      ]
      const thisState = this.$store.state
      const getDeviceLink = function (medMac) {
        return deviceLink(thisState, `00_1B_AD_00_${medMac}`, 'chimes')
      }
      // Battery tooltip implementation
      const battTooltipFrmt = function (row) {
        return batteryTooltip(thisState, {voltage: row.voltage, type: 'chimes', lastUpdate: row.msg301Time})
      }
      // Battery icon implementation
      const batteryIconFrmt = function (voltage) {
        return batteryIcon(voltage, 'chimes')
      }
      const chimeConfig = this.pageConfig.chimeNetwork
      // alert icon config object
      const icnObj = {
        'shock': 'shock',
        'tilt': 'tilt',
        'temperature': 'alert_temp',
        'low_temp': {name: 'alert_temp', color: 'blue'},
        'high_temp': 'alert_temp',
        'humidity': 'alert_humidity'
      }
      // Alert icon formatter function. Loop through the icons and filters which are not available in the
      // property object
      const alertIcnFrmt = function (props) {
        const mapFn = function (icnName) {
          let color = 'red'
          let name = icnObj[icnName]
          let size = 18
          if (typeof icnObj[icnName] === 'object') {
            name = icnObj[icnName].name
            if (icnObj[icnName].color) color = icnObj[icnName].color
            if (icnObj[icnName].size) size = icnObj[icnName].size
          }
          return getIcon(name, color, size)
        }
        return Object.keys(icnObj).filter(icn => props[icn] && props[icn] !== 0).map(mapFn).join('')
      }
      // Alert icons tooltip formatter function
      const alertIcnTooltip = function (row) {
        return Object.keys(icnObj).filter(icn => row.devProps[icn] && row.devProps[icn] !== 0).map(icn => `${capitalize(icn)}: ${row.devProps[icn]}`).join('</br>')
      }
      // crateNoFrmt - Get the crate number from the props
      const crateNoFrmt = function (props) {
        // skipcq:JS-W1043 - Skip redundant literal in a logical expression
        return props.crateNo || ''
      }
      const chimeTblOpts = {
        alertIcons: {label: '', id: 'devProps', formatter: alertIcnFrmt, tooltip: alertIcnTooltip},
        crateNo: {label: 'Crate #', id: 'devProps', formatter: crateNoFrmt},
        medMac: {label: 'MAC', id: 'medMac', formatter: getDeviceLink},
        note: {label: 'ID', id: 'note'},
        intact: {label: '', id: 'intact', formatter: securityIcon},
        voltage: {label: 'Battery', id: 'voltage', formatter: batteryIconFrmt, tooltip: battTooltipFrmt},
        details: {label: '', id: 'details', controls: controlIcons}
      }
      // Loop the each config item. If the config is an object, then overwrite the label value from configuration
      // Else return the config based on the key value
      const mapFn = function (key) {
        if (typeof key === 'object') {
          let column = chimeTblOpts[key.name]
          column.label = key.label
          return column
        } else {
          return chimeTblOpts[key]
        }
      }
      const chimesTblOpts = chimeConfig.map(mapFn)
      return chimesTblOpts
    },
    // Reutrns the chime data available for the shipment from state
    chimeTableData () {
      // const shpmnt = this.$store.state.shipments.all[this.$route.query.id]
      const chimes = this.shipment.chimes ? Object.values(this.shipment.chimes) : []
      return chimes
    },
    // Returns the Chime table heading value with recent record date and time value
    chimeTableHeaderText () {
      if (this.chimeTableData.length > 0) {
        const times = this.chimeTableData.filter(chm => chm.msg301Time && Number(chm.msg301Time)).map(chm => chm.msg301Time)
        const msg301Time = Math.max(...times)
        const tmz = this.$store.state.user.timezone
        const update = msg301Time > 0 ? moment(msg301Time).tz(tmz).format(`${getDateFormat()} HH:mm z`) : false
        let returnTxt = `Chimes (${this.chimeTableData.length})`
        returnTxt = !update ? returnTxt : `${returnTxt} - Last Update: ${update}`
        return returnTxt
      }
      return ''
    },
    // Returns the intact status value from the shipment
    // If the expected and actual value matches, then return Yes
    // Else returns N/A as the output
    getIntactStatus () {
      // skipcq:JS-W1043 - Skip redundant literal in a logical expression
      let acl = this.shipment.acl || '0:0'
      const regex = /([\d]+)\s+\/\s+([\d]+)/
      const aclResult = acl.match(regex)
      let intact = 'No' //  Default value is 'No'
      if (acl === '-') {
        intact = 'N/A'
      } else if (aclResult && aclResult[1] <= 0) { // N/A if Exp <=0
        intact = 'N/A'
      } else if (aclResult && aclResult[1] === aclResult[2]) { // Yes if Exp = Act
        intact = 'Yes'
      }
      return intact
    },
    // Get the markers list for the map
    getMapMarkers () {
      const markers = this.getMarkers(this.$route.query.id, this.sliderValue, this.activeMarkers)
      this.setActiveMarkers(markers.activeMarkers)
      return markers.timeSeriesData
    },
    /**
     * getNotificationTabs - Returns the list of tabs and the notification list by type
     * This property will group the notifcation by it's event type and returns as tab configurations
     * Here the alerts are groups by it's event type value, and also return the unacknowledged
     * boolean value to highlight the tab
     */
    getNotificationTabs () {
      const vm = this
      // Get the alert data by event type
      const getAlertEvents = function (event) {
        const alerts = vm.$store.getters['alerts/getAlertsByGuid'](vm.shipment.guid, event)
        const haveUnAcknowledged = alerts.filter(alert => alert.ackBy.length === 0).length > 0
        return {data: alerts, unAcknowledged: haveUnAcknowledged}
      }
      const alertData = getAlertEvents('2')
      const noticeData = getAlertEvents('0')
      let notifications = {alerts: {name: `Alerts `, value: alertData.data, unAcknowledged: alertData.unAcknowledged}}
      if (this.showAlertMessage) {
        const msgData = getAlertEvents('1')
        notifications['message'] = {name: `Messages `, value: msgData.data, unAcknowledged: msgData.unAcknowledged}
      }
      notifications['notices'] = {name: `Notices `, value: noticeData.data, unAcknowledged: noticeData.unAcknowledged}
      return notifications
    },
    getSetPoints () {
      // skipcq:JS-W1043 - Skip redundant literal in a logical expression
      return this.pageConfig.setPoints || false
    },
    headerBoxEntries () {
      // Return the list of header box items from the header configuration
      if (!this.shipment.loaded) return []
      let headers = []
      const headingConfig = this.pageConfig.header || []
      let removeHeader = []
      //  If the shipment is decommissioned then remove the 'Time Since Commission', 'Time Since Depart' options from the list
      if (!this.shipment.decommDate) {
        // If the shipment not decommissioned then remove the 'Shipment Duration', 'Transit Time', 'Decommission Date' columns
        removeHeader = ['Shipment Duration', 'Transit Time', 'Decommission Date']
        if (!this.shipment.departDate) removeHeader.push('Time Since Depart')
      }
      const headings = headingConfig.filter(col => !removeHeader.includes(col.label))
      headers = astShpmntDataFormat(this.$store.state, headings, this.shipment)
      return headers
    },
    itemName: function () {
      // return header for list - use mode + 's'
      const mode = this.$route.query.mode
      return capitalize(mode)
    },
    /**
     * locationChartOptions - Prepare the chart options for the location chart
     * The markers will be constructed from the shipment's locData
     * This method format the series and tooltip value from the location object
     * and return the chart option using the scatterChartOptionFactory
     */
    locationChartOptions: function () {
      // Get the location data for the current shipment and send it to chart options
      const calFmt = this.$store.state.configuration.calendarDateFmt
      const motionMap = this.$store.state.configuration.motionMap
      const tmzn = this.$store.state.user.timezone ? this.$store.state.user.timezone : moment.tz.guess()
      const locData = this.getLocationData().map(loc => {
        let displayTime = moment(loc.datetime).tz(tmzn).calendar(null, calFmt)
        if (loc.lastDatetime) {
          displayTime = `First - ${displayTime}<br>Last - ${moment(loc.lastDatetime).tz(tmzn).calendar(null, calFmt)}`
        }
        return [loc.timestamp, loc.locationType, parseInt(loc.entryId), `${loc.locationType}<br>${displayTime}<br>Motion: ${motionMap[loc.motion]}`]
      })
      const title = 'Location'
      const colors = ['rgb(0,128,0)', 'rgb(34,66,144)', 'rgb(255,140,0)']
      const colorIndex = {
        'Cell': 0,
        'GPS': 1,
        'iGate': 2
      }
      const chartOptions = {
        symbol: 'pin',
        size: [15, 20],
        colors: colors,
        colorsCallback: function (dataItem) {
          return colorIndex[dataItem[1]] ? colors[colorIndex[dataItem[1]]] : colors[0]
        },
        tooltipFormatter: function (params) {
          return params[3]
        }
      }
      return scatterChartOptionFactory(title, locData, this.sliderValue[0], this.sliderValue[1], chartOptions, tmzn)
    },
    mapWidthCalc: function () {
      // Get the calculated parent width for the map
      return getMapWidth(this.$parent.windowWidth, 0.4)
    },
    pageName: function () {
      // return title for page - use mode + 'Detail'
      return `${this.itemName} Detail`
    },
    polygons: function () {
      // getAssociations is a recursive function that will get all associations in route and (if defined) any associations
      // that are child associations of these associations.
      const getAssociations = (id) => {
        if (!this.$store.state.geofences.all[id]) {
          return false
        }
        const associations = [...this.$store.state.geofences.all[id].associationList]
        if (associations) {
          for (const assoc of associations) {
            associations.push(...getAssociations(assoc))
          }
          return associations
        }
        return false
      }
      // If the route is hidden or not yet initialized
      if (!this.activeMarkers.geofence || !this.activeMarkers.geofence.active || !this.routeInitialized) return {editable: false, paths: []}
      // get route and geozones
      const routeId = this.$store.state.shipments.all[this.$route.query.id].routeId
      const geozoneIds = getAssociations(routeId)
      // Map coordinates and remove any that are blank
      const polygons = geozoneIds.filter(gz => this.$store.state.geofences.all[gz]).map(gz => this.$store.state.geofences.all[gz].coordinates).filter(crd => crd.length > 0)
      // if (polygons.length > 0 && this.activeMarkers.hasOwnProperty('geofence')) {
      if (polygons.length > 0 && Object.prototype.hasOwnProperty.call(this.activeMarkers, 'geofence')) {
        const activeMarkers = {...this.activeMarkers}
        activeMarkers.geofence.hide = false
        this.setActiveMarkers(activeMarkers)
      }
      return {editable: false, paths: polygons}
    },
    reportOptions: function () {
      // Detail card options from configuration
      const reportOptions = this.pageConfig.reports
      if (reportOptions.length === 0) return []
      const showShockRpt = this.shipment.shock && Object.values(this.shockData).length > 0 // Available if the shipment is shock enabled and have shock records
      // All the available card option listed below. Only the options available in configuration and it's show condition is true will be returned as the output.
      const allReportOptions = {
        shockReport: {icon: 'download_pdf', text: 'Shock Report', optionArgs: {callbackFn: this.openReportModal, params: 'shipmentShockReport'}, show: showShockRpt},
        amatShipment: {icon: 'download_pdf', text: 'AMAT Shipment Report', optionArgs: {callbackFn: this.openReportModal, params: 'amatShipment'}, show: true},
        asmlShipment: {icon: 'download_pdf', text: 'ASML Shipment Report', optionArgs: {callbackFn: this.openReportModal, params: 'asmlShipment'}, show: true},
        assetDataReport: {icon: 'download_pdf', text: 'Asset Data Report', optionArgs: {callbackFn: this.openReportModal, params: 'assetDataReport'}, show: showShockRpt},
        assetHistory: {icon: 'download_csv', text: 'Asset History', optionArgs: {callbackFn: this.openReportModal, params: 'assetHistory'}, show: true},
        communicationReport: {icon: 'download_pdf', text: 'Communication Report', optionArgs: {callbackFn: this.openReportModal, params: 'vehicleCommunicationReport'}, show: true},
        locationHistory: {icon: 'download_csv', text: 'Location History', optionArgs: {callbackFn: this.openReportModal, params: 'locationHistory'}, show: true},
        probeTemperature: {icon: 'download_csv', text: 'Probe Temperature', optionArgs: {callbackFn: this.openReportModal, params: 'probeTemperature'}, show: true},
        tempHumidReport: {icon: 'download_csv', text: 'Temperature / Humidity', optionArgs: {callbackFn: this.openReportModal, params: 'temperatureHumidity'}, show: true},
        utilizationReport: {icon: 'download_pdf', text: 'Utilization Report', optionArgs: {callbackFn: this.openReportModal, params: 'vehicleUtilizationReport'}, show: true}
      }
      // Loop the each config item. If the config is an object, then overwrite the text value from configuration
      // Else return the config based on the key value
      const mapFn = function (key) {
        if (typeof key === 'object') {
          let column = allReportOptions[key.name]
          column.text = key.text
          return column
        } else {
          return allReportOptions[key]
        }
      }
      const userReports = this.$store.state.user.reports
      // Get the cardoptions from the list and filter based on the show value
      // const filteredOptions = reportOptions.map(mapFn).filter(col => col.show)
      const filteredOptions = reportOptions.map(mapFn).filter(col => col.show && userReports.includes(col.optionArgs.params))
      return Object.values(filteredOptions)
    },
    routeInitialized: function () {
      /* only show the route once the shipment and it's route have been initilaized */
      const shpmnt = this.$store.state.shipments.all[this.$route.query.id] || {}
      const routeId = shpmnt.routeId || -1
      return this.$store.state.geofences.all[routeId] ? this.$store.state.geofences.all[routeId].initialized : false
    },
    selectedReport: function () {
      // Return the selected report object for the selected report key
      const config = {...this.$store.state.reports.all[this.reportKey]}
      config.reportKey = this.reportKey
      if (!config.controlList) return config
      // If the controls have start and end date, Then set the start and end date
      // as the shipments create date and decommission/today date
      if (config.controlList.includes('startDate') || config.controlList.includes('endDate')) {
        const disableDates = {}
        // If the control have start date, then use the create date to disable
        // the past dates before the create date
        if (config.controlList.includes('startDate')) {
          const startDate = shortDate(this.shipment.createDate, 'YYYY-MM-DD')
          disableDates.startDate = startDate
        }
        // If the control have end date, then use the decomm/today date to disable
        // the future dates from the end date
        if (config.controlList.includes('endDate')) {
          const decommDate = this.shipment.decommDate || moment.utc()
          const endDate = shortDate(decommDate, 'YYYY-MM-DD')
          disableDates.endDate = endDate
        }
        // Apply the computed date to the report config
        config.dateConfig = {}
        const dateConfig = config.dateConfig || {}
        dateConfig.disableDates = disableDates
        config.dateConfig = dateConfig
      }
      return config
    },
    shipmentDetails () {
      if (!this.shipment || !this.shipment.loaded) return []
      // Detail field list from configuration
      const detailFields = this.pageConfig.details || []
      // skipcq:JS-W1043 - Skip redundant literal in a logical expression
      const parentMode = this.pageConfig.parentMode || 'shipment'
      let removeColumns = []
      const siteOptions = this.$store.state.configuration.siteOptions
      const hideProvisioner = !siteOptions.showProvisioner // ?also && this.shipment.provisionerName === '-'
      // Remove scanner check temporarily...
      // if (this.shipment.scanner === '' || typeof this.shipment.scanner === 'undefined') { removeColumns.push('Scanner') }
      // Remove shock/tilt field, if it is configured false
      if (!this.shipment.tilt) removeColumns.push('Tilt Enabled')
      if (!this.shipment.shock) removeColumns.push('Shock Enabled')
      // Hide Arr Dest Country if showArrDestCountry is false
      if (!siteOptions.showArrDestCtry) { removeColumns.push('Arr Dest Country') }
      // Hide BOL if showBOL is false
      if (!siteOptions.showBOL) { removeColumns.push('BOL') }
      // If siteOptions.showProvisioner is false, then remove the Provisioner field from list
      if (hideProvisioner) { removeColumns.push('Provisioner') }
      // If aclActual count is 0, then don't show the Intact field
      if (this.shipment.aclActual === 0) { removeColumns.push('Intact') }
      const dateFmtStr = this.dateFormatStr
      const fullDate = function (date) {
        return shortDate(date, dateFmtStr)
      }
      const thisState = this.$store.state // State object reference
      // Get the deviceType based on the shipment's configType
      const deviceType = getShpmntDeviceType(this.$store.state, this.shipment.configType)
      // Device link formatter
      const getDeviceLink = function (mac) {
        return deviceLink(thisState, mac, deviceType, -11)
      }
      const motionMap = this.$store.state.configuration.motionMap
      const mapMotion = function (mot) {
        // Return motion map value if it exists
        return ['E', 'S', 'D', 'M'].includes(mot) ? motionMap[mot] : '-'
      }
      const thisShipment = this.shipment // Current shipment object
      const billOfLadingLink = function () {
        if (thisShipment.billOfLadingLink === '-') return '-'
        // Bill of lading link generation
        const bolType = thisShipment.billOfLadingType && thisShipment.billOfLadingType.length > 1 ? ` [${thisShipment.billOfLadingType}]` : ''
        return `<a href="javascript:void(0)" onClick="window.open('${thisShipment.billOfLadingLink}', '_blank', 'width=800,height=800', true)" target="_blank">${thisShipment.billOfLadingValue}${bolType}</a>`
      }
      // Parent shipment link for the asset details page
      const parentShpmntLink = function (parent) {
        if (parent === 'None') return parent
        return `<a href="detail?mode=${parentMode}&id=${parent}">${parent.substring(0,8)}</a>`
      }
      // Returns the device charging status
      const chargeStatus = function (status) {
        return parseInt(status) > 0 ? 'Yes' : 'No'
      }
      // All fields configured for the shipment/asset detail page
      const allFields = {
        billOfLadingLink: {label: 'Tracking Reference', id: 'billOfLadingLink', formatter: billOfLadingLink},
        createDate: {label: 'Create Date', id: 'createDate', formatter: fullDate},
        clientShipmentId: {label: 'Serial Number', id: 'clientShipmentId'},
        commDate: {label: 'Commission', id: 'commDate', formatter: fullDate},
        chargeStatus: {label: 'Charging', id: 'customInt', formatter: chargeStatus},
        destination: {label: 'Location', id: 'destination'},
        customer: {label: 'Part Number', id: 'customer'},
        decommDate: {label: 'Decommission', id: 'decommDate', formatter: fullDate},
        departDate: {label: 'Depart', id: 'departDate', formatter: fullDate},
        inCountryArrDate: {label: 'Arr Dest Country', id: 'inCountryArrDate'},
        intact: {label: 'Intact', id: 'intact'},
        lastComms: {label: 'Last Comms', id: 'lastComms', formatter: fullDate},
        lastStageCmdTime: {label: 'Last Stage', id: 'lastStageCmdTime', formatter: fullDate},
        mode: {label: 'Mode', id: 'mode'},
        motion: {label: 'Motion', id: 'notes', formatter: mapMotion},
        device: {label: 'iTag', id: 'macId', formatter: getDeviceLink},
        notes: {label: 'Notes', id: 'notes'},
        origin: {label: 'Origin', id: 'origin'},
        provisionerName: {label: 'Provisioner', id: 'provisionerName'},
        scanner: {label: 'Scanner', id: 'scanner'},
        parentShpmntLink: {label: 'Shipment Record', id: 'parent', formatter: parentShpmntLink},
        shippingStateStr: {label: 'Status', id: 'shippingStateStr'},
        shock: {label: 'Shock Status', id: 'shock'},
        tilt: {label: 'Tilt Status', id: 'tilt'},
        zoneId: {label: 'Zone', id: 'zoneId'},
        timeInZone: {label: 'Time In Zone', id: 'timeInZone', formatter: relativeTime},
        zoneTime: {label: 'Zone Time', id: 'zoneTime', formatter: fullDate}
      }
      // Map function to loop the fields from configuration
      // If the field is a string, the get it's config from the allFields object
      // If the field is an object, then get the config from field.name and overwrite the label if it have label property
      // Returns {label, value} as the output for the list
      const mapFn = function (fld) {
        const fieldConfig = typeof fld === 'object' ? allFields[fld.name] : allFields[fld]
        let label = fieldConfig.label
        let value = thisShipment[fieldConfig.id]
        // if (fieldConfig.hasOwnProperty('formatter')) value = fieldConfig.formatter(value)
        if (Object.prototype.hasOwnProperty.call(fieldConfig, 'formatter')) value = fieldConfig.formatter(value)
        // if (typeof fld === 'object' && fld.hasOwnProperty('label')) label = fld.label
        if (typeof fld === 'object' && Object.prototype.hasOwnProperty.call(fld, 'label')) label = fld.label
        return {label, value}
      }
      // Map and remove the fields, when the data is not available or based on site/user permission
      const filteredFields = detailFields.map(mapFn).filter(col => !removeColumns.includes(col.label))
      return filteredFields
    },
    amatShipmentExternalControls: function () {
      // Returns the default values for the shock report form
      return {
        guid: {type: 'hidden', value: this.shipment.guid}
      }
    },
    asmlShipmentExternalControls: function () {
      // Returns the default values for the shock report form
      return {
        guid: {type: 'hidden', value: this.shipment.guid}
      }
    },
    assetDataReportExternalControls: function () {
      // Returns the default values for the shock report form
      return {
        guid: {type: 'hidden', value: this.shipment.guid},
        deviceMacId: {type: 'hidden', value: this.shipment.macId}
      }
    },
    shipmentShockReportExternalControls: function () {
      // Returns the default values for the shock report form
      return {
        guid: {type: 'hidden', value: this.shipment.guid},
        deviceMacId: {type: 'hidden', value: this.shipment.macId}
      }
    },
    temperatureHumidityExternalControls: function () {
      // Returns the default values for the shock report form
      return {
        guid: {type: 'hidden', value: this.shipment.guid}
      }
    },
    locationHistoryExternalControls: function () {
      const startDate = shortDate(this.shipment.createDate, 'YYYY-MM-DD')
      const decommDate = this.shipment.decommDate || moment.utc()
      const endDate = shortDate(decommDate, 'YYYY-MM-DD')
      // Returns the default values for the probe Temperature report form
      return {
        guid: {type: 'hidden', value: this.shipment.guid},
        startDate: {value: startDate},
        endDate: {value: endDate}
      }
    },
    probeTemperatureExternalControls: function () {
      // Returns the default values for the probe Temperature report form
      return {
        guid: {type: 'hidden', value: this.shipment.guid}
      }
    },
    assetHistoryExternalControls: function () {
      // Returns the default values for the Asset History report form
      return {
        guid: {type: 'hidden', value: this.shipment.guid}
      }
    },
    vehicleCommunicationReportExternalControls: function () {
      // Returns the default values for the vehicle communication report form
      return {
        guid: {type: 'hidden', value: this.shipment.guid},
        shipmentId: {type: 'hidden', value: this.shipment.clientShipmentId}
      }
    },
    vehicleUtilizationReportExternalControls: function () {
      // Returns the default values for the vehicle utilization report form
      return {
        guid: {type: 'hidden', value: this.shipment.guid},
        shipmentId: {type: 'hidden', value: this.shipment.clientShipmentId}
      }
    },
    shockChartOptions: function () {
      // Mapped alerts fiters all alerts and gets those with correct guid & alert event #
      //  filter these values to the data required by the chart.
      const mappedAlerts = this.getAlertTimeseries(this.$route.query.id, 221)
      const title = 'Shock'
      /* let minTime = Number(moment(this.slider.value[0], 'M-DD-YY').format('x'))
      let maxTime = Number(moment(this.slider.value[1], 'M-DD-YY').endOf('day').format('x')) */
      const data = mappedAlerts.filter(isBetween(this.sliderValue))
      const tmz = this.$store.state.user.timezone ? this.$store.state.user.timezone : moment.tz.guess()
      return scatterChartOptionFactory(title, data, this.sliderValue[0], this.sliderValue[1], {}, tmz)
    },
    shockData: function () {
      // Get the shock data for the current shipment
      if (!this.$store.state.shipments.all[this.$route.query.id]) return {}
      return this.getAlertEvents(this.$route.query.id, 221)
    },
    /**
     * tempChartOptions - Prepare the chart options for the temperature chart
     * The series value list prepared from the shipment's tsData list
     * Will filter the temperature value from the time series data
     * The final chart option prepared from the buildTempAndHumidChartOptions
     */
    tempChartOptions: function () {
      // Returns Temperature chart options
      // Set variable items for chart
      const isC = this.$store.state.user.temperatureUnit === 'Celsius'
      // Filter to remove any bad data (temp of exactly 32F or 0 C is a bad record)
      function tempFilter (t) {
        if (isC) {
          return t[1] !== 0.0
        } else {
          return t[1] !== 32.0
        }
      }
      this.setChartData('temperatureChartData', this.getShipmentTimeSeries(this.$route.query.id, this.sliderValue, 'temperature'))
      const chartConfig = this.$store.state.configuration.charts
      const tmz = this.$store.state.user.timezone ? this.$store.state.user.timezone : moment.tz.guess()
      const options = {
        temp: this.temperatureChartData.filter(tempFilter),
        humid: [],
        isC,
        chartConfig,
        tmz
      }
      return buildTempAndHumidChartOptions(options)
    },
    /**
     * tempHumidChartOptions - Prepare the chart options for the temperature and humidity chart
     * The series value list prepared from the shipment's tsData list
     * Will filter the temperature & humidity value from the time series data
     * The final chart option prepared from the buildTempAndHumidChartOptions
     */
    tempHumidChartOptions: function () {
      // Returns Temperature and Humidity chart options
      // Set variable items for chart
      this.setChartData('temperatureChartData', this.getShipmentTimeSeries(this.$route.query.id, this.sliderValue, 'temperature'))
      // If the timeseries record have the humidity value, then the humidity option will be included to the chart
      // const humidityExists = this.$store.state.shipments.all[this.$route.query.id].tsData[0] && this.$store.state.shipments.all[this.$route.query.id].tsData[0].hasOwnProperty('humidity')
      const humidityExists = this.$store.state.shipments.all[this.$route.query.id].tsData[0] && Object.prototype.hasOwnProperty.call(this.$store.state.shipments.all[this.$route.query.id].tsData[0], 'humidity')
      let humidtyData = []
      if (humidityExists) {
        this.setChartData('humidityChartData', this.getShipmentTimeSeries(this.$route.query.id, this.sliderValue, 'humidity'))
        humidtyData = this.humidityChartData.filter(h => h[1] > 0.0 && h[1] < 100.0)
      }
      const isC = this.$store.state.user.temperatureUnit === 'Celsius'
      function tempFilter (t) {
        if (isC) {
          // return entry !== 0.0
          return t[1] !== 0.0
        } else {
          return t[1] !== 32.0
        }
      }
      const chartConfig = this.$store.state.configuration.charts
      const tmz = this.$store.state.user.timezone ? this.$store.state.user.timezone : moment.tz.guess()
      const options = {
        temp: this.temperatureChartData.filter(tempFilter),
        humid: humidtyData,
        isC,
        chartConfig,
        tmz
      }
      return buildTempAndHumidChartOptions(options)
    },
    /**
     * tiltChartOptions - Prepare the chart options for the tilt chart
     * The series value list prepared from the alerts and filtered using the shipment's GUID
     * And the event type 220 for tilt alert records
     * The final chart option prepared from the scatterChartOptionFactory
     */
    tiltChartOptions: function () {
      // Mapped alerts fiters all alerts and gets those with correct guid & alert event #
      //  filter these values to the data required by the chart.
      const mappedAlerts = this.getAlertTimeseries(this.$route.query.id, 220)
      // Define the rest of the chart fields
      const title = 'Tilt'
      /* let minTime = Number(moment(this.slider.value[0], 'M-DD-YY').format('x'))
      let maxTime = Number(moment(this.slider.value[1], 'M-DD-YY').endOf('day').format('x')) */
      const data = mappedAlerts.filter(isBetween(this.sliderValue))
      const tmz = this.$store.state.user.timezone ? this.$store.state.user.timezone : moment.tz.guess()
      return scatterChartOptionFactory(title, data, this.sliderValue[0], this.sliderValue[1], {}, tmz)
    },
    cellAouList: function () {
      // Returns the map configuration to all cell tower Areas of Uncertainty.   These are  opaque circles shown around cell towers.
      // AOUs are hidden if: 1. configured to be disable 2. high zoom level 3. no cell locations
      if (!this.showCellTower || !this.activeMarkers.Cell || !this.activeMarkers.Cell.active) {
        return []
      }
      const options = {
        fillColor: '#008000',
        fillOpacity: 0.5,
        strokeColor: '#008000',
        strokeWeight: 0
      }
      const duplicateMrkrs = []
      const mapFn = mrk => {
        // console.log('mrk', mrk)
        const identifier = `${mrk.position.lat}|${mrk.position.lng}`
        let dup = false
        if (!duplicateMrkrs[identifier]) {
          duplicateMrkrs[identifier] = identifier
        } else {
          dup = true
        }
        mrk.center = mrk.position
        mrk.dup = dup
        mrk.options = options
        // mrk.radius = 2000
        mrk.editable = false
        mrk.visible = true
        return mrk
      }
      let markers = this.getMarkers(this.$route.query.id, this.sliderValue, this.activeMarkers).timeSeriesData.filter(mrk => mrk.icon === 'cell').map(mapFn)
      markers = markers.filter(mrk => !mrk.dup) // Filter the duplicate cell points
      return markers
    },
    ...mapGetters({
      getAlertEvents: 'alerts/getAlertEvents',
      getAlertTimeseries: 'alerts/getAlertTimeseries',
      getMarkers: 'shipments/getSingleShipmentMarkers',
      getPaths: 'shipments/getSingleShipmentPaths',
      getShipmentTimeSeries: 'shipments/getShipmentTimeSeries'
    }),
    ...mapState({
      chartClick: 'chartClick',
      shipments: 'shipments'
    })
  },
  created () {
    // Initialize reports to get the shock chart report
    this.$store.dispatch('reports/initialize')
    // Call updateShipments and load/update shipment object
    this.$store.dispatch('shipments/updateSingleShipment', this.$route.query.id).then(() => {
      this.refreshShipment()
      if (!this.$store.state.initialized.includes('shipments')) {
        this.$store.commit('setInitializedModule', 'shipments')
      }
      const routeName = this.$store.state.shipments.all[this.$route.query.id].routeName
      Promise.all([
        // this['shipments/fetchShipmentEvents']([this.$route.query.id, startDate, endDate]),
        this.$store.dispatch('alerts/fetchAlerts', {guid: this.$route.query.id}),
        this.$store.dispatch('geofences/initializeSingleRoute', {routeName: routeName, guid: this.$route.query.id})
      ]).then(() => {
        this.refreshShipment()
        // Initialize Route to ensure map populates.
      })
    }).catch(e => {
      // skipcq: JS-0002.  Allow console.error
      console.error('Error', e)
      // If the shipment have any issues, redirect to error page
      router.push({ name: 'Error', query: { code: '404_1', fullPath: this.prevRoute.fullPath } })
    })
    this.$store.dispatch('waypoints/initialize')
    // get the messages for the detail page
    this.$store.dispatch('getPageMessage', 'detail')
  },
  data () {
    const mode = this.$route.query.mode
    const pageConfig = this.$store.state.configuration.pageOptions[`${mode}Detail`]
    const showCellTowerIcon = this.$store.state.configuration.siteOptions.cellAouDisplayed
    const dateFormatStr = `${getDateFormat()} HH:mm z`
    const changeDateFormat = function (dateValue) {
      return shortDate(dateValue, dateFormatStr)
    }
    // skipcq: JS-D1001.  Allow documentation comment
    const shockUnit = function (shock) {
      return shock && shock.length > 0 ? `${shock} g` : ''
    }
    // show/hide the icon for the shock table list. If the alert is not enabled, then hide the icon
    const showIcon = function (row) {
      return row.enabled
    }
    // Shock table icon list
    const shockTableIcons = [
      {icon: 'new_window', callback: this.openShockModal, tooltip: 'View chart', show: showIcon}
    ]
    // For the table sort option, we are hiding the prefixed timestamp value by splitting the delimitter('##')
    // The tmestamp value is prefixed with the formatted date like 123456789##5 Nov 23:45
    const hideTimestamp = function (dt) {
      return dt.split('##')[1]
    }
    // If the location string value is > 40, then trim it and add the ... after the string
    // The full value will display in tooltip, when we hover it
    const trimLocation = function (location) {
      // let locationStr = cityState(location)
      const locationStr = (location === '') ? '-' : location.substr(0, 40)
      return (locationStr.length > 40) ? `${locationStr}...` : locationStr
    }
    // Location column tooltip for the location list table
    const displayLocation = function (location) {
      // if (typeof location.locationString === 'undefined') return ''
      // const locationStr = cityState(location.locationString)
      // skipcq:JS-W1043 - Skip redundant literal in a logical expression
      return location.locationString || '-'
    }
    // Toggle the icon for the active location
    const highlightLocationIcon = function (location) {
      return location.activeLocation
    }
    // Toggle the icon for the active location
    const showLocationIcon = function (location) {
      return !location.activeLocation
    }
    // Locaiton table icon list
    const locationControlIcons = [
      {icon: 'location_arrived', callback: this.zoomToLocation, tooltip: 'Zoom out', show: highlightLocationIcon},
      {icon: 'location_dot', callback: this.zoomToLocation, tooltip: 'Zoom to location', show: showLocationIcon}
    ]
    // Locaiton table column list
    const locationColumns = [
      {label: 'Times (#)', id: 'times', formatter: hideTimestamp},
      {label: 'Location', id: 'locationString', formatter: trimLocation, tooltip: displayLocation},
      {label: 'Type', id: 'locationType'},
      {label: 'Zone', id: 'zoneId'},
      {label: '', id: 'locationType', controls: locationControlIcons}
    ]
    const activeMarkers = {}
    // skipcq: JS-W1044.  Allow Logical operator can be refactored to optional chain
    const pageMarkers = pageConfig.map && pageConfig.map.markers ? pageConfig.map.markers : []
    pageMarkers.forEach((mrk) => {
      activeMarkers[mrk] = {active: true, hide: true}
    })
    const addEditFormObj = {
      title: '',
      callback: () => {},
      formElements: []
    }
    const messageDetails = getMessageObject(this.$store.state, 'detail', 'setPoints', {})
    return {
      activeMarkers,
      addEditFormObj,
      alertObj: false,
      activeTab: 'alerts',
      batteryChartData: [],
      cmdModal: {},
      currentZoom: false,
      customCenter: false,
      dateFormatStr,
      deviceDetail: {},
      editShpDefaults: {},
      externalControls: {},
      humidityChartData: [],
      isDisabled: false,
      locationColumns,
      locationData: {},
      locationTableoptions: {
        paginate: true,
        sortable: true,
        sortType: 'desc',
        sortIndex: 0,
        perPage: 5
      },
      markers: pageConfig.map.markers,
      messageDetails,
      modalIsActive: {
        addEdit: false,
        addFlight: false,
        chimeDetail: true,
        chimeNetworkDetail: true,
        createAlert: false,
        deleteShipment: false,
        reportConfig: false,
        reportResults: false,
        sendCommand: false,
        shipmentShareLink: false,
        shock: true,
        showMessage: false
      },
      mode,
      networkChartAlerts: pageConfig.networkChartAlerts,
      networkCharts: pageConfig.networkCharts,
      // networkCharts: false,
      pageCharts: [],
      pageConfig,
      parentMode: 'container',
      reportData: [],
      reportFileName: '',
      reportKey: '',
      shipment: {
        guid: this.$route.query.id,
        loaded: false
      },
      shockTableCols: [
        {label: '', id: 'datetime', controls: shockTableIcons},
        {label: 'Time', id: 'datetime', formatter: changeDateFormat},
        {label: 'Magnitude', id: 'raw', dblclick: this.openShockModal, formatter: shockUnit}
      ],
      shockTableOptions: {
        paginate: false,
        sortable: true,
        sortIndex: 1,
        sortType: 'desc'
      },
      showAclRefresh: true,
      showAlertMessage: this.$store.state.configuration.siteOptions.enableAlertMessages,
      showCellTower: showCellTowerIcon,
      showCellTowerIcon,
      showDirection: pageConfig.map?.showDirection ? pageConfig.map.showDirection : false,
      // showLocHistory: pageConfig.hasOwnProperty('showLocHistory') ? pageConfig.showLocHistory : true,
      showLocHistory: Object.prototype.hasOwnProperty.call(pageConfig, 'showLocHistory') ? pageConfig.showLocHistory : true,
      shareModalDesc: 'Use one of the following methods to share a link to details of this shipment. This link will provide the recipient with access a live view of this shipment. The recipient will not have access to any other shipment information, nor will they be able to edit or control the shipment in any manner.<br><br>',
      sliderEnd: moment.utc().valueOf(),
      sliderInterval: [30, 7],
      sliderValue: [moment.utc().valueOf(), moment.utc().valueOf()],
      temperatureChartData: [],
      zoomFixed: false,
      zoomValue: -1
    }
  },
  methods: {
    // Toggle the Tab on clicking the tab
    activateTab (tabId) {
      if (this.activeTab !== tabId) {
        this.activeTab = tabId
      }
    },
    changeCurrentLocation () {
      // If this.customCenter value exists, set the zoomFixed value as false to avoid the zoom out issue
      if (this.customCenter) {
        this.zoomFixed = false
      }
      this.customCenter = false
      this.currentZoom = !this.currentZoom
      this.zoomFixed = !this.zoomFixed
      this.zoomValue = (this.currentZoom ? 12 : -1)
    },
    getSetPointDetails () {
      const setPoints = this.getSetPoints
      if (!setPoints || setPoints.length === 0) return
      const points = {}
      setPoints.forEach(point => {
        points[point.key] = point
      })
      const keys = Object.keys(points).join(',')
      const vm = this
      // formattedGuidMap
      const payload = {
        query: 'formattedGuidMap',
        params: [vm.shipment.guid, keys]
      }
      const getFormattedValue = function (formatter, value) {
        let outputStr = value
        if (formatter === 'humidity') {
          outputStr = outputStr ? `${outputStr} %` : outputStr
        } else if (formatter === 'temperature') {
          if (outputStr && Number(outputStr) && Number(outputStr) > 0) {
            const isC = vm.$store.state.user.temperatureUnit === 'Celsius'
            const unit = isC ? ' \xB0C' : ' \xB0F'
            outputStr = isC ? round((Number(outputStr) - 32) * 5 / 9, 1) : outputStr
            outputStr = `${outputStr} ${unit}`
          }
        } else if (formatter === 'battery') {
          outputStr = outputStr ? `${outputStr} (v)` : outputStr
        }
        return outputStr
      }
      vm.modalIsActive.showMessage = true
      vm.messageDetails.message = 'Fetching Data...'
      const messageDetails = getMessageObject(this.$store.state, 'detail', 'setPoints', {})
      // Get the query results and return the option list
      getQueryProvider(vm.$store.state, payload).then((res) => {
      // skipcq: JS-W1044.  Allow Logical operator can be refactored to optional chain
        if (res.data && res.data[0]) {
          const resData = isJSON(Object.values(res.data[0])[0]) ? JSON.parse(Object.values(res.data[0])[0]) : {}
          const resKeys = Object.keys(resData)
          let tblStr = 'No Record Found'
          if (resKeys.length > 0) {
            tblStr = `${messageDetails.message}<table><thead><th>Parameter</th><th>Setting</th></thead><tbody>`
            resKeys.forEach(key => {
              if (points[key]) {
                const value = getFormattedValue(points[key].formatter, resData[key])
                tblStr += `<tr><td>${points[key].label}</td><td>${value}</td></tr>`
              }
            })
            tblStr += '</tbody></table>'
          }
          vm.messageDetails.message = tblStr
        }
      }).catch(e => {
        // skipcq: JS-0002.  Allow console.error
        console.error('Get SetPoint Details ERROR: ', e)
        vm.messageDetails.message = `Data Fetching Failed: ${e}`
      })
    },
    // Common command modal integraton
    openSendCmdModal (args) {
      // skipcq:JS-W1043 - Skip redundant literal in a logical expression
      const eventEmit = args.eventEmit || false
      const shpmnt = this.shipment
      let devType = shpmnt.deviceType
      devType = devType.charAt(0).toUpperCase() + devType.slice(1)
      // getMessageObject will return object that contain the message details
      const getMessageObj = getMessageObject(this.$store.state, 'detail', args.event, {...shpmnt, devType})
      this.cmdModal.event = eventEmit
      this.cmdModal.messageObj = getMessageObj
      this.cmdModal.callback = this.runActivityCommandsCallback
      this.cmdModal.params = {
        payload: args.payload
      }
      this.modalIsActive.sendCommand = true
    },
    // Exports the location data in the csv format
    exportLocData: function () {
      const shipment = {...this.$store.state.shipments.all[this.$route.query.id]}
      const locData = shipment.locData
      const exprtFmt = this.$store.state.configuration.csvExportDateFormat
      /**
       * Map through the each item and constructs the output array
       */
      const mapFn = function (loc) {
        return [moment(loc.datetime).format(exprtFmt), loc.latitude, loc.longitude, loc.locationType, `"${loc.locationString}"`, `"${loc.zoneId}"`,loc.motion]
      }
      const exportData = locData.map(mapFn)
      const headers = ['Date Time', 'Latitude', 'Longitude', 'Location Type', 'Location', 'Zone', 'Motion']
      exportAsCSV(headers, exportData, 'location-data.csv')
    },
    fetchAlerts: function () {
      const vm = this
      // Disable the refresh button for shock alerts
      vm.isDisabled = true
      // Fetch the alerts for the shipment
      vm.$store.dispatch('alerts/fetchAlerts', {guid: vm.$route.query.id})
      // Enable the refresh button for shock alerts
      setTimeout(function(){ vm.isDisabled = false }, 5000)
    },
    fetchChimeData: function () {
      const vm = this
      const shipment = vm.shipment
      this.showAclRefresh = false
      this['shipments/fetchChimeData']([shipment.macId, shipment.guid, vm.networkCharts])
      setTimeout(function(){ vm.showAclRefresh = true }, 5000)
    },
    /**
     * Fetch the shipment data on initial Page load or when the slider gets updated
     * @param {initCall} Boolean || Object - Will get the mixed param type
     * Boolean on Inital page load the pass the start and end date based on the state value
     * Note: If the shipment loading for the first time, the date value from the slider
     * will be used as the parameters for the API. Otherwise the stae value will be used to
     * get the latest message based on the recent/latest message time stored in the state.
     */
    fetchShipmentEventData ({initCall = false, force = false} = {}) {
      const shipment = {...this.$store.state.shipments.all[this.shipment.guid]}
      if (initCall) { // On initial page load
        const startDate = moment(this.sliderValue[0]).format('X')
        const endDate = moment(this.sliderValue[1]).format('X')
        this.fetchShipmentEvents(startDate, endDate, true)
        return
      } if (force) {
        const startDate = shipment.eventDataEnd
        const endDate = moment().format('X').valueOf()
        this.fetchShipmentEvents(startDate, endDate, true)
        return
      }
      // Not the initial call or a forced load, only fetch data if there is new data to process
      const newData = moment(this.sliderValue[0]).format('X') < shipment.eventDataStart // check for new data > slider End
      if (newData) {
        const startDate = moment(this.sliderValue[0]).format('X').valueOf()
        const endDate = shipment.eventDataStart || moment(this.sliderValue[1])
        this.fetchShipmentEvents(startDate, endDate, true)
      }
    },
    /**
     * Fetch the shipment events between the given start and end dates.
     * @param {startDate} Date - Least date value to get the events
     * @param {endDate} Date - End date value for the query param
     * @param {updateState} Boolean - To update teh date value in the state. If the
     * shipment loading again means, the date value shouldn't update to the state.
     */
    fetchShipmentEvents (startDate, endDate, updateState = false) {
      const shipment = {...this.$store.state.shipments.all[this.shipment.guid]}
      const vm = this
      vm.isDisabled = true
      const minEvent = shipment.eventDataStart ? parseInt(shipment.eventDataStart) : Infinity
      const maxEvent = shipment.eventDataEnd ? parseInt(shipment.eventDataEnd) : 0
      const min = Math.min(parseInt(startDate), minEvent)
      const max = Math.max(endDate, maxEvent, moment.utc(shipment.lastComms).format('X'))
      const dateRangeData = {
        eventDataStart: min,
        eventDataEnd: max
      }
      const enableSlider = function () {
        if (updateState) {
          vm.$store.commit('shipments/UPDATE_SHIPMENT', {id: vm.$route.query.id, data: dateRangeData})
        }
        setTimeout(() => { vm.isDisabled = false }, 1500)
      }
      this['shipments/fetchShipmentEvents']([this.$route.query.id, startDate, endDate, vm.pageConfig.map.markers]).then(() => {
        enableSlider()
      }).catch(e => {
        // skipcq: JS-0002.  Allow console.error
        console.error('Error in fetchShipmentEvents', e)
        enableSlider()
      })
    },
    getACLCommandsPayload () {
      if (!this.$store.state.shipments.all[this.shipment.guid]) return {}
      const shipment = {...this.$store.state.shipments.all[this.shipment.guid]}
      // Get chime list from shipment
      const chimes = Object.keys(shipment.chimes)
      const commands = [...this.$store.state.configuration.commands.buildACL]
      // Replacement Object preparation
      const replacerObj = {
        count: chimes.length,
        broadcast: shipment.broadcast,
      }
      // deviceIdList for the command API
      const deviceIdList = [shipment.macId]
      if (commands.includes('{{chimeList}}')) {
        const chimeList = getACLCommands(chimes)
        replacerObj.chimeList = chimeList
      }
      // Get the Command list for the API for the chimes
      const commandList = getCommandList(commands, replacerObj)

      // Send ACL Command Payload Preparation
      const sendACLPayload = {
        event: 'buildAcl',
        eventEmit: {event: 'updateLastStageCmdTime'},
        payload: {
          action: 'sendCommands',
          deviceIdList,
          commandList
        }
      }
      return sendACLPayload
    },
    /**
     * Return the computed value
     * @param {String} key - Value of computed variable name
    */
    getChartData: function (key) {
      return this[key]
    },
    // Get the location list for the current shipment
    getLocationData: function () {
      // Gets the location data for the current shipment and adds the
      // aciveLocation attribute to the location object for toggle icon functionality in the table.
      if (!this.shipment.loaded) return []
      const locData = [...this.$store.state.shipments.all[this.shipment.guid].locData]
      Object.values(locData).forEach((loc) => {
        if (typeof loc.activeLocation === 'undefined' || (!this.customCenter && loc.activeLocation)) {
          loc.activeLocation = false
        }
        const shortDateFmt = `${getDateFormat('short')} HH:mm`
        let times = loc.lastDatetime ? `${shortDate(loc.timestamp, shortDateFmt)}  -  ${shortDate(loc.lastDatetime, shortDateFmt)}  &nbsp;(${loc.count})` : shortDate(loc.timestamp, shortDateFmt)
        // Prefix the timestam value for sorting purpose
        // The timestamp will be removed from the display using a formatter
        loc.times = `${loc.timestamp}##${times}`
        this.locationData[loc.entryId] = loc
      })
      return Object.values(this.locationData).filter(isBetween(this.sliderValue))
    },
    lookupProjectId: function () {
      // getMessageObject will return object/Boolean(false)
      const msgPayload = lookupProjectMsgHelper(this.shipment)
      const getMessageObj = getMessageObject(this.$store.state, 'detail', 'lookupProjectId', msgPayload)
      // Opens the send command modal for to send the lookupProjectId for the current shipment
      this.cmdModal.messageObj = getMessageObj
      this.cmdModal.callback = lookupProjectIdHelper
      this.cmdModal.params = {row: this.shipment}
      this.modalIsActive.sendCommand = true
    },
    openReportModal (reportKey) {
      // Opens the report modal for the incoming report key
      this.reportKey = reportKey
      // Get the external controls for the given report key
      // `${reportKey}ExternalControls` - Computed property assigned for the report key
      this.externalControls = this[`${reportKey}ExternalControls`] ? this[`${reportKey}ExternalControls`] : {}
      this.openModal('reportConfig')
    },
    openShipmentModals (modalName) {
      // Open the shipment related modals from the card options
      this.editShpDefaults = {}
      this.addEditFormObj.formElements = []
      // If the the modal is addEdit shipment modal means, set the default value object prop for the modal
      if (modalName === 'addEdit') {
        const shp = {...this.$store.state.shipments.all[this.shipment.guid]}
        if (this.mode === 'shipment') {
          shp.billOfLading = {
            billOfLadingType: shp.billOfLadingType,
            billOfLadingValue: shp.billOfLadingValue,
            entryIdBOL: shp.entryIdBOL
          }
        }
        if (shp.destinationCoords) {
          shp.destLatitude = shp.destinationCoords.lat ? shp.destinationCoords.lat.toString() : ''
          shp.destLongitude = shp.destinationCoords.lng ? shp.destinationCoords.lng.toString() : ''
        }
        shp.companyId = String(this.$store.state.company.id)
        this.editShpDefaults = shp

        // Set the edit form inputs and callback function
        this.addEditFormObj.title = `${this.pageConfig.editShipmentForm.label}: ${this.shipment.clientShipmentId}`
        this.addEditFormObj.callback = this.updateShipmentCallback
        this.addEditFormObj.defaults = this.editShpDefaults
        this.addEditFormObj.formElements = this.pageConfig.editShipmentForm.fields
        // this.modalIsActive.addEdit = true
        // return
      } else if (modalName === 'associateTag') {
        // Associate tag functionality implementation for ASML
        this.addEditFormObj.title = 'Associate iTag'

        // Prepare the query params
        const companyId = this.$store.state.company.id
        const guid = this.shipment.guid

        // Prepare the form fields
        const flds = [{type: 'simpleSelect', field: 'macId', label: 'Tag', searchable: true, source: {type: 'query', query: 'activeContainerDevices', params: [companyId.toString()]}}, {type: 'hidden', field: 'guid'}]

        // Set the form field and attributes
        this.addEditFormObj.formElements = flds
        this.addEditFormObj.callback = this.associateTagCallback
        this.addEditFormObj.defaults = {guid}
        this.addEditFormObj.title = 'Associate Tag'
        this.openModal('addEdit')
        return
      }
      this.openModal(modalName)
      // if (modalName === 'addEdit') this.modalIsActive.addEdit = true
    },
    associateTagCallback (params) {
      // console.debug('params', params)
      return new Promise((resolve, reject) => {
        getQueryProvider(this.$store.state, {query: 'joinChimeAndTag', params: [params.guid, params.macId]}).then(() => {
          // console.debug('res', res)
          resolve({message: 'Successfully Associated the tag'})
        }).catch(e => {
          reject(e)
        })
      })
    },
    openShockModal (row) {
      // Opens the shock modal from the shock table or chart
      // If the incoming shock dat is not enabled, then do not open the modal
      if (!row.enabled) {
        return
      }
      this.alertObj = false
      if (row.position?.lat) this.alertObj = row
      this.modalIsActive.shock = true
      this.fetchShockData({macId: this.shipment.macId, timestamp: row.timestamp, counter: row.shockCount, acceleration: row.acceleration})
    },
    previewReport (reportData) {
      // Opens the report preview modal
      // Assigns the preview table data from the response for the selected report
      this.reportData = reportData.data
      this.reportFileName = reportData.fileName
      // Open results modal
      this.modalIsActive.reportResults = true
    },
    refreshShipment () {
      // Once the shipment is loaded, assign the additional calculated/formatted required shipment values
      if (this.$store.state.shipments.all[this.$route.query.id]) {
        const shpmnt = this.$store.state.shipments.all[this.$route.query.id]
        // All the available charts for the page
        let pageCharts = [
          {key: 'tempHumid', classname: 'chart-style', ClickHandler: ''},
          {key: 'temp', classname: 'chart-style', ClickHandler: ''},
          {key: 'battery', classname: 'chart-style', ClickHandler: ''},
          {key: 'location', classname: 'chart-style-tilt-shock', ClickHandler: 'location'}
        ]
        // Adds the tilt chart, if the tilt enabled for the shipment
        if (shpmnt.tilt) {
          pageCharts.push({key: 'tilt', classname: 'chart-style-tilt-shock', ClickHandler: 'tilt'})
        }
        // Adds the shock chart, if the tilt enabled for the shipment
        if (shpmnt.shock) {
          pageCharts.push({key: 'shock', classname: 'chart-style-tilt-shock', ClickHandler: 'shock'})
        }
        if (this.mode === 'shipment') {
          const tagNote = shpmnt.tags[shpmnt.macId.substring(12)] ? `[${shpmnt.tags[shpmnt.macId.substring(12)].note}]` : ''
          const vm = this
          // Get the alert date for the given event from the alert list
          const getalertDateByEvent = function (event) {
            let alertDate = Object.values(vm.$store.state.alerts.all).filter((alert) => { return (alert.guid === vm.$route.query.id && parseInt(alert.event) === event) })
            return (alertDate.length === 0) ? '-' : shortDate(alertDate[0].time, vm.dateFormatStr)
          }
          // Set values
          this.$set(this.shipment, 'acl', shpmnt.acl)
          this.$set(this.shipment, 'aclActual', Number.parseInt(shpmnt.aclActual))
          this.$set(this.shipment, 'billOfLading', this.billOfLading)
          this.$set(this.shipment, 'client', shpmnt.customer)
          this.$set(this.shipment, 'device', `${shpmnt.macId.substring(18)} ${tagNote}`)
          this.$set(this.shipment, 'intact', this.getIntactStatus)
          this.$set(this.shipment, 'inCountryArrDate', getalertDateByEvent(17))
          this.$set(this.shipment, 'intactDate', getalertDateByEvent(18))
          this.$set(this.shipment, 'provisionerName', getProvisionerName(shpmnt.pickItemNotes))
          this.$set(this.shipment, 'scanner', shpmnt.scanner.substring(18))
          this.$set(this.shipment, 'shipmentId', shpmnt.clientShipmentId)
        }
        this.$set(this.shipment, 'chimeCount', shpmnt.chimes ? Object.keys(shpmnt.chimes).length : 0)
        // Filters the page charts which are not available in the configuration
        this.pageCharts = pageCharts.filter(ch => this.pageConfig.charts.includes(ch.key))
        this.shipment = Object.assign({}, this.shipment, shpmnt)
        this.$set(this.shipment, 'deviceType', getShpmntDeviceType(this.$store.state, shpmnt.configType))
        // Reset map config
        this.zoomFixed = false
        this.customCenter = false
        this.currentZoom = false
        const activeMarkers = {}
        // skipcq: JS-W1044.  Allow Logical operator can be refactored to optional chain
        const pageMarkers = this.pageConfig.map && this.pageConfig.map.markers ? this.pageConfig.map.markers : []
        pageMarkers.forEach((mrk) => {
          activeMarkers[mrk] = {active: true, hide: true}
        })
        this.activeMarkers = activeMarkers
        this.shipment.loaded = true
      }
    },
    refreshSlider () {
      /* Shipment should be loaded - now calculate values */
      // Once the shipment data loaded properly, the slider start and end date values
      // will be defined here with the shipment values
      const shpmnt = this.$store.state.shipments.all[this.$route.query.id]
      const sliderConfig = this.pageConfig.slider
      const scale = sliderConfig.scale
      // If the page config has slider's start and range values as -1
      // Then calculate the start, end values from the shipment's
      // created and decommissioned date values.
      // Object.prototype.hasOwnProperty.call(obj, key)
      // End date will be the decommissioned date of the shipment or current date
      let end = shpmnt.decommDate || moment.utc()
      this.sliderEnd = moment(end).utc().valueOf()
      // if slider range or start not defined, keep default
      if (!Object.prototype.hasOwnProperty.call(sliderConfig, 'range') || !Object.prototype.hasOwnProperty.call(sliderConfig, 'start')) {
        this.fetchShipmentEventData({initCall: true})
      } else {
        // Start date will be the created date of the shipment
        const createDate = moment(shpmnt.createDate).subtract(1, scale)
        let range = moment(end).utc().diff(createDate, scale)
        // # if range is -1 use the entire shipment/asset duration
        if (sliderConfig.range > 0 && range > sliderConfig.range) {
          // # slider is smaller of current shipment/asset duration or configured range
          // # it should never be longer than the existing duration....
          // range = minimum(end - createDate, pageConfig.slider.range())
          range = sliderConfig.range
        }
        let start = range + 0
        // # if start is -1 use the create date -1 for start
        if (sliderConfig.start > 0 && start > sliderConfig.start) {
          // # slider is smaller of range + 1 or configured start
          // # it should never be greater than the existing range....
          // start = minimum(range + 1, pageConfig.slider.start())
          start = sliderConfig.start
        }
        // If range is less than the start value due to misconfiguration
        // Then replace the range value with start value
        if (range < start) start = range
        this.sliderInterval = [range, start]
        this.fetchShipmentEventData({initCall: true})
      }
    },
    runActivityCommandsCallback: function (store, args) {
      // Calls the run activity/send command with the values passed in the argument.
      // When the request completed, updates the current shipment with latest values
      // Also updates the alerts for the current shipment
      const guid = this.shipment.guid
      const payload = args.payload
      const action = payload.action
      delete payload.action
      const promise = new Promise(function (resolve, reject) {
        store.dispatch(action, payload).then(() => {
          store.dispatch('shipments/updateSingleShipment', guid)
          store.dispatch('alerts/fetchAlerts', {guid})
          // const retVal = {'status': 200, 'message': args.success}
          resolve({})
        }).catch(e => {
          // skipcq: JS-0002.  Allow console.error
          console.error('ERROR: runActivityCommandsCallback error', e)
          // const retVal = {'status': 417, 'message': `${args.error}<br>${e}`}
          reject(e)
        })
      })
      return promise
    },
    // Replace the activeMarkers data property with updated marker values
    setActiveMarkers: function (activeMarkers) {
      this.activeMarkers = activeMarkers
    },
    setChartData: function (key, data) {
      // Sets the chart data dynamicall for the given key
      this[key] = data
    },
    showChimeDetailModal (device) {
      // Sets the device detail from the argument and opens the chime detail modal
      this.deviceDetail = device
      this.openModal('chimeDetail')
    },
    updateRange (val) {
      // const loaded = this.shipment.loaded
      // Update range captures the slider component value and update the sliderValue here
      this.sliderValue = val
      // Fetch the shipment data, once the slider range updated
      // if (this.shipment.loaded) this.fetchShipmentEventData()
      const shipment = {...this.$store.state.shipments.all[this.shipment.guid]}
      // Define the initcall param. By default the init will be true as the slider initialized the value
      let initCall = true
      // If the eventDataStart value found in the shipment object, the slider is alreday initialized
      // If the shipment.eventDataStart > slider start then reinitialize the slider
      if (shipment.eventDataStart) initCall = moment(val[0]).format('X') < shipment.eventDataStart
      this.fetchShipmentEventData({initCall})
    },
    updateShipmentCallback: function (shpValues) {
      const guid = this.shipment.guid
      const entryId = Number(this.$store.state.shipments.all[guid].entryIdSPL)
      const formElements = this.pageConfig.editShipmentForm.fields
      // Create mapping to convert form names to those required by API
      const fieldMap = {
        customer: 'customData2',
        notes: 'customData3',
        entryIdBOL: 'entryIdBOL',
        clientShipmentId: 'customData',
        customInt: 'stateInt1',
        destination: 'dest'
      }
      let editPayload = {
        guid,
        entryId
      }
      // Loop through the fields passed and add to payload based on map
      for (const itm of formElements) {
        if (itm.field) {
          if (Array.isArray(itm.field)) {
            for (const fld of itm.field) {
              if (fieldMap[fld]) editPayload[fieldMap[fld]] = shpValues[fld]
            }
          } else {
            if (fieldMap[itm.field]) editPayload[fieldMap[itm.field]] = shpValues[itm.field]
          }
        }
      }
      // if (shpValues.hasOwnProperty('props') && Object.keys(shpValues.props).length > 0) {
      if (Object.prototype.hasOwnProperty.call(shpValues, 'props') && Object.keys(shpValues.props).length > 0) {
        const shipmentProps = this.$store.state.shipments.all[guid].props && typeof this.$store.state.shipments.all[guid].props === 'object' ? {...this.$store.state.shipments.all[guid].props} : {}
        const props = Object.assign(shipmentProps, shpValues.props)
        editPayload.customText = JSON.stringify(props)
        editPayload.props = props
      }
      if ((shpValues.billOfLadingType && shpValues.billOfLadingValue) || (shpValues.destination && shpValues.destLatitude && shpValues.destLongitude)) {
        editPayload.entryIdBOL = shpValues.entryIdBOL
        if (shpValues.billOfLadingType && shpValues.billOfLadingValue) {
          editPayload.itemId = shpValues.billOfLadingType
          editPayload.itemText = shpValues.billOfLadingValue
          editPayload.itemType = 100
        } else {
          editPayload.destination = shpValues.destination
          editPayload.destLatitude = shpValues.destLatitude
          editPayload.destLongitude = shpValues.destLongitude
          editPayload.destinationCoords = {lat: shpValues.destLatitude, lng: shpValues.destLongitude}
        }
      }
      const vm = this
      return new Promise(function (resolve, reject) {
        vm.$store.dispatch('shipments/editShipment', editPayload).then((response) => {
          vm.refreshShipment()
          resolve(response)
        }).catch(e => {
          // skipcq: JS-0002.  Allow console.error
          console.error('ERROR: updateShipmentCallback error ', e)
          // const retVal = {'status': 417, 'message': `${args.error}:<br> ${e}`}
          reject(e)
        })
      })
    },
    // emitFunctionCallback - Generic function called after command executed from send command modal
    // The received event will be executed from the generic event function
    emitFunctionCallback (eventObj) {
      // eventObj.arg - The input required for the receiver function
      // skipcq:JS-W1043 - Skip redundant literal in a logical expression
      const eventArg = eventObj.arg || ''
      if (this[eventObj.event]) this[eventObj.event](eventArg)
    },
    updateLastStageCmdTime () {
      const shipment = {...this.$store.state.shipments.all[this.shipment.guid]}
      const entryId = shipment.entryIdSPL
      const vm = this
      vm.$store.dispatch('shipments/updateLastStageCmdTime', entryId).then((response) => {
        const data = {
          lastStageCmdTime: response.updateTime
        }
        vm.$store.commit('shipments/UPDATE_SHIPMENT', {id: vm.$route.query.id, data})
        vm.refreshShipment()
      })
    },
    /** Zoom the clicked cell point in location history table
     * @param {location} Object - Clicked location row Object
     */
    zoomToLocation (location) {
      this.currentZoom = false
      if (this.customCenter && this.customCenter.entryId !== location.entryId) {
        this.locationData[this.customCenter.entryId].activeLocation = false
      }
      this.locationData[location.entryId].activeLocation = !location.activeLocation
      this.zoomFixed = location.activeLocation
      this.zoomValue = (location.activeLocation ? 12 : -1)
      const centerObject = {
        entryId: location.entryId,
        location: {lat: parseFloat(location.latitude), lng: parseFloat(location.longitude)}
      }
      this.customCenter = location.activeLocation ? centerObject : false
    },
    ...mapActions([
      // 'createAlert',
      'shipments/fetchShipmentEvents',
      'shipments/fetchChimeData',
      'fetchShockData',
      'runActivity'
    ])
  },
  mixins: [modalWindowManager],
  mounted () {
    this.modalIsActive.chimeDetail = false
    this.modalIsActive.chimeNetworkDetail = false
    this.modalIsActive.shock = false
  },
  watch: {
    $route: function (to) {
      // Every time query parameters change, load additional items (if required) and then update
      // the mode and page configuration
      this.mode = to.query.mode
      this.pageConfig = this.$store.state.configuration.pageOptions[`${this.mode}Detail`]
      this.refreshShipment()
    },
    'chartClick': {
      handler: function (val) {
        // Whenever the chart is clicked, the value will be updated in the state
        // When the state value update, this watchher will be executed
        // If the last click is location, then zoom to it's position in the map
        if (this.chartClick.lastClick === 'location' && val.location && val.location.entryId && this.locationData[val.location.entryId]) {
          this.zoomToLocation(this.locationData[val.location.entryId])
        }
        let macId = this.$store.state.shipments.all[this.$route.query.id].macId
        // If the chime detail modal is open, then pass the chime ID to fetch the shock data
        if (this.modalIsActive.chimeDetail && this.deviceDetail.medMac) macId = `00_1B_AD_00_${this.deviceDetail.medMac}`
        // If the last click is shock, then open the shock modal for the shock event
        if (this.chartClick.lastClick === 'shock') {
          this.alertObj = false
          let acceleration = [0, -1, 0]
          let counter = 0
          if (this.chartClick.shock.entryId) {
            const alert = this.$store.state.alerts.all[this.chartClick.shock.entryId]
            if (!parseInt(alert.enabled)) {
              return
            }
            acceleration = alert.acceleration
            counter = parseInt(alert.stateInt2)
            this.alertObj = alert
          }
          this.modalIsActive.shock = true
          this.fetchShockData({macId: macId, timestamp: this.chartClick.shock.timestamp, acceleration, counter})
        }
      },
      deep: true
    },
    'shipment.loaded': {
      handler: function () {
        this.refreshSlider()
        // When the page is opened and it have the 'm' query params, open the chime/shock modal based on the value
        if (this.$route.query.m) {
          const vm = this
          const mode = vm.$route.query.m
          if (mode === 'chimeNetwork' && vm.shipment.chimeCount > 0) this.openModal('chimeNetworkDetail')
          if (mode === 'chimeDetail' && Object.values(vm.shipment.chimes).length > 0) {
            const medMac = vm.$route.query.mac
            const shortMacVal = medMac.slice(-11)
            if (vm.shipment.chimes[shortMacVal]) this.showChimeDetailModal(vm.shipment.chimes[shortMacVal])
          }
          const entryId = vm.$route.query.entryId
          if (entryId) {
            const getShockAlert = vm.shockData.filter(shk => shk.entryId === entryId)
            if (mode === 'shock' && getShockAlert.length > 0) vm.openShockModal(getShockAlert[0])
          }
        }
      },
      deep: false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .chart-style {
    height: 300px;
    float: none;
    clear: both;
  }
  .chart-style-tilt-shock {
    height: 100px;
    float: none;
    clear: both;
  }
  .slider-icon {
    border-left: 2px solid rgba(0, 0, 0, .5);
    border-bottom: 2px solid rgba(0, 0, 0, .5);
  }
  .btn-left {
    background: #FFF;
  }
  .btn-right {
    background: #FFF;
  }
  .copySection {
    position: absolute;
    z-index: -99999;
    left: -99999px;
  }
  .tabs ul.noMarginleft {
    margin-left: 0px;
    margin-top: 5px;
  }
  .text-danger {
    color: #dc3545;
    font-weight: bold;
  }
  .tabs ul.noMarginleft .is-active a {
    font-weight: bold;
    color: #000;
  }
  #chimeTable table tbody:before {
    content: '@';
    display: block;
    line-height: 15px;
    text-indent: -1000px;
  }
  .detail-page-slider {
    width: 86%;
    margin: 0px auto;
  }
</style>
