import moment from 'moment-timezone'
import * as echarts from 'echarts'
import {intelytApiProvider} from './providers'
import {chartOptionFactory} from './factories'

/**
 * @param {Object} obj - Given input object
 * @returns the matched string in lower case format
 */
const toType = function (obj) {
  return ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase()
}

/**
 * Calculates value of the input number rounded to given number of significant digits
 * @param {number} value - Value to be rounded
 * @param {number} decimals - Number of digits to round to (right of decimal point).
 * @returns {number} Rounded number.
*/
export const round = function (value, decimals) {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
  // another method:
  // let factor = Math.pow(10, decimals)
  // return Math.round(number * factor) / factor
}

/**
 * Builds objects to capture details of devices for a given shipment.
 * @param {object} shpmnt - Voltage value to be conveted
 * @returns {object} Base shipment object.
 */
export const buildDeviceDetails = (rootState, shpmnt) => {
  const notes = shpmnt.notes.split('&')
  // Define any required regexs
  const devRE = /([\d]{1})::00_1B_AD_00_([\w_]{11}).+?Info<~>(.+?)</i
  const deviceConfig = rootState.configuration.deviceConfig
  const chimeTestRegex = new RegExp(deviceConfig.chimeTestRegex)
  // Process Chime & Tag data
  const chimes = {}
  const tags = {}
  /* Get devices list from device field in DB and remove last element */
  const devices = shpmnt.devices.split('|')
  devices.splice(-1, 1)

  /* process notes field if it contains 30/1 data */
  const chimeDetails = {}
  if (chimeTestRegex.test(notes[0])) {
    const firstFour = notes[0].slice(0, 4)
    // Split string on first 4 characters and use the remaining data to build the chime status.
    // Note: the first 4 will be removed from the results.  Also, first elemet will be blank so it
    //        must be removed.
    const chimeList = notes[0].split(firstFour).slice(1)
    const scale = 0.000524986
    chimeList.forEach(chm => {
      // If the string has a pipe delimiter and timestamp, use this for the time of the 301 message, otherwise 0
      const chimeTime = chm.split('|')
      const msg301Time = chimeTime.length > 1 ? Number(chimeTime[1]) * 1000 : 0
      // Get counter and voltage from the remaining data
      const counter = parseInt(chm.substring(6, 8), 16) * 256 + parseInt(chm.substring(4, 6), 16)
      const voltage = scale * (parseInt(chm.substring(10, 12), 16) * 256 + parseInt(chm.substring(8, 10), 16))
      chimeDetails[`${chm.substring(0, 2)}_${chm.substring(2, 4)}`] = {voltage: round(voltage, 2), counter, msg301Time}
    })
  }
  // Process Device Details setting device object values.
  devices.forEach(dev => {
    if (devRE.test(dev)) {
      const devData = devRE.exec(dev)
      // Get the device props like shock, tilt and parent device details
      let devProps = devData.input.split('::')[3] || '{}'
      // skipcq: JS-0129.  Allow Variable used before definition (function name sorting)
      devProps = isJSON(devProps) ? JSON.parse(devProps) : {}
      const shortMac = devData[2].substring(6)
      // const devType = (devData[1] === '1' || parseInt(devData[2].substring(6, 8), 16) < 32) ? 'iTag' : 'iChime'
      // const devType = (iTagRE.test(dev) || devData[1] === '1') ? 'iTag' : 'iChime'
      const devType = devData[1] === '1' ? 'iTag' : 'iChime'
      const devObj = {
        devProps,
        parent: devProps.parent || '',
        type: devType,
        medMac: devData[2],
        shortMac: devData[2].substring(6),
        note: devData[3],
        counter: chimeDetails[shortMac] ? chimeDetails[shortMac].counter : 0,
        intact: chimeDetails[shortMac] ? chimeDetails[shortMac].counter > 0 : false,
        msg301Time: chimeDetails[shortMac] ? chimeDetails[shortMac].msg301Time : 0,
        voltage: chimeDetails[shortMac] ? chimeDetails[shortMac].voltage : 0.0,
        tiltCount: chimeDetails[shortMac] ? chimeDetails[shortMac].tiltCount : 0,
        tiltMagnitude: chimeDetails[shortMac] ? chimeDetails[shortMac].tiltMagnitude : 0,
        shockCount: chimeDetails[shortMac] ? chimeDetails[shortMac].shockCount : 0,
        shockMagnitude: chimeDetails[shortMac] ? chimeDetails[shortMac].shockMagnitude : 0
      }
      if (devType === 'iChime') {
        chimes[devData[2]] = devObj
        // chimeDisplay = devData[2].substring(6)
      } else {
        tags[devData[2]] = devObj
        // Assumption: one tag per shipment
        // if (devData[3] !== '-') tagDisplay = `${shpmnt.macId.slice(-5)} [${devData[3]}]`
      }
    }
  })
  return [chimes, tags]
}

/**
 * Returns a formatted object to send to map to populate hover over tooltips
 * asd
 * @param {Object} obj - Map object that contains details for object.
 * @param {number} indx - Index value of the marker
 * @param {String} tmzn - Timezone from the user preferences
 * @returns {Ojbect} Marker object suitable to send to map
 */
export const buildMarker = (obj, indx, tmzn = moment.tz.guess()) => {
  // Get the localstorage value
  let stateObj = localStorage.getItem('intelyt')
  stateObj = JSON.parse(stateObj)

  // if locdata advance markerId to make slot for origin
  const markerId = obj.class === 'locdata' ? indx + 1 : indx
  let displayTime = moment(obj.datetime).tz(tmzn).calendar(null, stateObj.configuration.calendarDateFmt)
  if (obj.lastDatetime) {
    displayTime = `First - ${displayTime}<br>Last - ${moment(obj.lastDatetime).tz(tmzn).calendar(null, stateObj.configuration.calendarDateFmt)}`
  }
  const destTimeString = obj.datetime ? displayTime : 'Not Arrived'
  const locIcon = obj.locationType ? obj.locationType.toLowerCase() : ''
  const textObj = {
    alert: `Alert<br>${obj.subject}<br>${displayTime}`,
    asset: `ID: ${obj.clientShipmentId}<br>Location: ${obj.location}`,
    currentLocation: `Type: Current Location<br>Time: ${displayTime}`,
    destination: `Destination<br>${obj.destination}<br>${destTimeString}`,
    device: `ID: ${obj.macId}<br>Status: ${obj.status}<br>Charge: ${obj.charge * 100}%<br>Voltage: ${obj.voltage}V`,
    locdata: `${obj.locationType}<br>${displayTime}<br>Motion: ${stateObj.configuration.motionMap[obj.motion]}`,
    origin: `Origin<br>${obj.origin}<br>${displayTime}`,
    warehouse: `Warehouse: ${obj.name}<br>Gateway: ${obj.macId}`,
    waypoint: `'Geozone Center <br>${obj.destination}`,
    yardparts: `ID: ${obj.clientShipmentId}<br>Location: ${obj.location}}`
  }
  const iconObj = {
    alert: 'alertIcon',
    asset: 'currentLocationIcon',
    currentLocation: 'currentLocationIcon',
    destination: 'destinationIcon',
    device: 'currentLocationIcon',
    locdata: locIcon,
    origin: 'origin',
    warehouse: 'currentLocationIcon',
    waypoint: 'waypointIcon',
    yardparts: 'currentLocationIcon'
  }
  const marker = {
    id: markerId,
    icon: iconObj[obj.class] || '',
    position: {
      lat: Number.parseFloat(obj.latitude),
      lng: Number.parseFloat(obj.longitude)
    },
    infoText: textObj[obj.class] || 'Not Defined',
    queryId: obj.class === 'Asset' ? obj.guid : obj.macId,
    timestamp: obj.timestamp
  }
  if (obj.cellAccuracy) {
    marker.radius = obj.cellAccuracy
  }
  return marker
}

/**
 * Generate the bill of landing link
 * @param {string} boltype - Bill of landing type
 * @param {string} bolvalue -  Bill of landing value
 * @returns {string} tracklink - Bil of landing link
 */
 export const createBOLLink = (boltype, bolvalue) => {
  if (boltype === '' || bolvalue === '') return '-'
  let trackLink = 'https://connect.track-trace.com/for/intelyt'
  const typeValue = boltype.toLowerCase()
  switch (typeValue) {
    case 'hawb':
      trackLink += `/aircargo/${bolvalue}/action,direct`
      break
    case 'mawb':
      trackLink += `/aircargo/${bolvalue}/action,direct`
      break
    case 'ocean container':
      trackLink += `/container/${bolvalue}/action,direct`
      break
    case 'bol':
      trackLink += `/billoflading/${bolvalue}/action,direct`
      break
    default:
      trackLink = '-'
  }
  return trackLink
}

/**
 * Builds detailed object from shipment query results
 * @param {object} shpmnt - Voltage value to be conveted
 * @returns {object} Base shipment object.
 */
export const buildshipmentDetail = (rootState, shpmnt) => {
  const dateFrmtStr = 'ddd MMM DD HH:mm:ss YYYY'
  const imageRE = /image:([\w|\s|-]+\.(png|jpg|gif|bmp));/i
  const destRE = /address:([\w|\s|,]+);/i
  const destinationAddress = destRE.exec(shpmnt.customText)
  const image = imageRE.exec(shpmnt.customText)
  const retData = {
    createDate: moment.tz(shpmnt.createDate, dateFrmtStr, 'UTC'),
    // class: (shpmnt.transitMode.includes('Asset') || shpmnt.configType === '4') ? 'Asset' : 'Shipment',
    mode: shpmnt.transitMode.includes('Asset') ? '-' : shpmnt.transitMode,
    conveyances: [],
    entryIdSPL: shpmnt.entryId,
    // image: imageRE.test(shpmnt.customText) ? imageRE.exec(shpmnt.customText)[1] : 'unknown',
    image: image ? image[1] : 'unknown',
    onTimeState: shpmnt.onTimeState,
    onTimeStateStr: rootState.stateDefs.onTime[shpmnt.onTimeState],
    parent: shpmnt.parent || 'None',
    routeName: shpmnt.route || false,
    saveStatus: shpmnt.saveStatus,
    securityState: shpmnt.securityState,
    statusNotes: shpmnt.statusNotes,
    tagDisplay: shpmnt.macId.slice(-5),
    // Location Details
    // destinationAddress: destRE.test(shpmnt.customText) ? destRE.exec(shpmnt.customText)[1] : '',
    destinationAddress: destinationAddress ? destinationAddress[1] : '',
    destinationCoords: {
      lat: Number.parseFloat(shpmnt.destLatitude),
      lng: Number.parseFloat(shpmnt.destLongitude)
    },
    originCoords: {
      lat: Number.parseFloat(shpmnt.originLatitude),
      lng: Number.parseFloat(shpmnt.originLongitude)
    }
  }
  // Bill of Lading
  if (shpmnt.billOfLading !== '-') {
    const [entryIdBOL, billOfLadingType, billOfLadingValue] = shpmnt.billOfLading.split('|')
    retData.entryIdBOL = entryIdBOL || 0
    retData.billOfLadingType = billOfLadingType || ''
    retData.billOfLadingValue = billOfLadingValue || ''
    retData.billOfLadingLink = createBOLLink(retData.billOfLadingType, retData.billOfLadingValue)
  }
  return retData
}

/**
 * Builds detailed object from Temperature and humidity chart
 * @param {object} temp - Temperature Data
 * @param {object} humid - Humidity Data
 * @param {object} chartConfig - Chart Configurations
 * @param {Boolean} isC - Temperature unit in Celsius or Fahrenheit
 * @param {Boolean/String} title - title for the chart
 * @returns {object} Base chart object.
 */
export const buildTempAndHumidChartOptions = ({temp = [], humid = [], isC = false, title = false, chartConfig = {}, tmz = 'Etc/GMT'} = {}) => {
  if (!title) {
    title = humid.length > 0 ? 'Temperature and Humidity' : 'Temperature'
  }
  const tempUnit = isC ? ' \xB0C' : ' \xB0F'
  const series = [{name: `Temperature ${tempUnit}`, data: temp}]
  const yAxis = []
  const vMapPieces = [false]
  const tempScale = chartConfig.scales.temperature
  const tempYMin = isC ? tempScale.minC : tempScale.minF
  const tempYMax = isC ? tempScale.maxC : tempScale.maxF
  yAxis.push({formatter: '', ymax: tempYMax, ymin: tempYMin})
  if (humid.length > 0) {
    series.push({name: 'Humidity', data: humid, yAxisIndex: series.length})
    yAxis.push({name: 'Humidity', formatter: '', ymax: 100, ymin: 0})
    vMapPieces.push('humidity')
  }
  const options = {
    vMapPieces,
    tooltip: 'datetime',
    yAxis,
    colors: chartConfig.colors.lineChart,
    legend: {bottom: 5}
  }
  return chartOptionFactory(chartConfig, title, series, options, {}, tmz)
}

/**
 * Converts a voltage value to a percentage of given device type charge
 * @param {Number} voltage - Battery voltage
 * @param {String} type - Device Type
 * @returns {Number} returns the calculated charge value.
 */
export const calcDeviceCharge = (rootState, {voltage = 0, type = 'chimes'} = {}) => {
  // Get the device voltage values from the configuration
  const batteryParams = rootState.configuration.deviceConfig.constants.BATTERY_PARAMS
  const deviceConfig = batteryParams[type]
  let charge = 0
  if (typeof deviceConfig === 'undefined') {
    return charge
  }
  const fullV = deviceConfig.fullV
  const emptyV = deviceConfig.emptyV
  if (voltage >= fullV) {
    charge = 1
  } else if (voltage < emptyV) {
    charge = 0
  } else {
    charge = (voltage - emptyV) / (fullV - emptyV)
  }
  return round(charge, 2)
}

/**
 *
 * @param {String} str - Input string value
 * @returns {Boolean} - Returns the given string is a JSON object or not
 */
export const isJSON = (str) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

/**
 * Builds base object from shipment query results
 * @param {object} shpmnt - Voltage value to be conveted
 * @returns {object} Base shipment object.
 */
export const buildShipmentBase = (rootState, [shpmnt, isNew]) => {
  const dateFrmtStr = 'ddd MMM DD HH:mm:ss YYYY'
  const aclString = parseInt(shpmnt.aclScanned) !== 0 ? `${shpmnt.aclExpected} / ${shpmnt.aclActual}` : '-'
  let shippingStateStr = '-'
  const classValue = rootState.configuration.shipmentConfigTypes[shpmnt.configType].class
  if (shpmnt.shippingState) {
    // Get the status from the shipment config
    if (rootState.stateDefs.shipping[shpmnt.shippingState]) shippingStateStr = rootState.stateDefs.shipping[shpmnt.shippingState]
    // If the transitMode = asset, then get the status from the asset config
    if (classValue === 'Asset') shippingStateStr = rootState.stateDefs.asset[shpmnt.shippingState] || ''
    // If the classValue/aconfigType = vehicle, then get the status from the vehicle config
    if (classValue === 'Vehicle') shippingStateStr = rootState.stateDefs.vehicle[shpmnt.shippingState] || ''
  }

  // const battRE = /ttery:\s*(\d+\.\d{1,2})/i
  // const battRE2 = /BattV:\s*(\d+\.\d{1,2})/i
  // let battery = battRE.test(shpmnt.notes) ? parseFloat(battRE.exec(shpmnt.notes)[1]) : 0.0
  // if (battery === 0.0 && battRE2.test(shpmnt.notes)) battery = parseFloat(battRE2.exec(shpmnt.notes)[1])

  let shipmentData = {
    guid: shpmnt.guid,
    acl: aclString,
    aclActual: shpmnt.aclActual,
    battery: shpmnt.battery,
    broadcast: shpmnt.broadcast,
    clientShipmentId: shpmnt.customData,
    class: classValue,
    createDate: moment.tz(shpmnt.createDate, dateFrmtStr, 'UTC'),
    commDate: shpmnt.commDate ? moment.utc(shpmnt.commDate, dateFrmtStr) : '',
    configType: shpmnt.configType,
    customer: shpmnt.customData2,
    customInt: shpmnt.customInt,
    departDate: shpmnt.departDate ? moment.utc(shpmnt.departDate, dateFrmtStr) : '',
    decommDate: shpmnt.decommDate ? moment.utc(shpmnt.decommDate, dateFrmtStr) : '',
    destination: shpmnt.destination,
    entryIdSPL: shpmnt.entryId,
    fakeAclString: parseInt(shpmnt.shippingState) > 1 ? `${shpmnt.aclExpected} / ${shpmnt.aclExpected}` : aclString,
    lastComms: moment.utc(shpmnt.lastComms, dateFrmtStr),
    lastStageCmdTime: moment.utc(shpmnt.lastStageCmdTime, dateFrmtStr),
    lastUpdate: moment.utc(shpmnt.lastUpdate, dateFrmtStr),
    latitude: shpmnt.latitude === 0 ? shpmnt.originLatitude : shpmnt.latitude,
    longitude: shpmnt.longitude === 0 ? shpmnt.originLongitude : shpmnt.longitude,
    macId: shpmnt.macId,
    mode: shpmnt.transitMode.includes('Asset') ? '-' : shpmnt.transitMode,
    notes: shpmnt.customData3,
    origin: shpmnt.origin,
    pickItemNotes: shpmnt.pickItemNotes,
    rebuildCount: shpmnt.rebuildCount || 0,
    shippingState: shpmnt.shippingState,
    shippingStateStr,
    statusNotes: shpmnt.statusNotes,
    type: rootState.configuration.shipmentConfigTypes[shpmnt.configType].type,
    zoneId: shpmnt.zoneId,
    zoneTime: shpmnt.zoneTime ? moment.utc(shpmnt.zoneTime, dateFrmtStr) : '',
    timeInZone: shpmnt.timeInZone
  }
  // If the shpmnt.customText is a JSON value, then apply it in the shipment object
  // Shock and Tilt will be stored in the attribute shpmnt.customText
  if (isJSON(shpmnt.customText)) {
    const props = JSON.parse(shpmnt.customText)
    // Prevent overriding the chimes in the shipment object
    if ( Object.prototype.hasOwnProperty.call(props, 'chimes') ) delete props.chimes
    shipmentData = Object.assign(shipmentData, props)
    shipmentData.props = props
  }
  // Bill of Lading
  if (shpmnt.billOfLading !== '-') {
    const [entryIdBOL, billOfLadingType, billOfLadingValue] = shpmnt.billOfLading.split('|')
    shipmentData.entryIdBOL = entryIdBOL || 0
    shipmentData.billOfLadingType = billOfLadingType || ''
    shipmentData.billOfLadingValue = billOfLadingValue || ''
    shipmentData.billOfLadingLink = createBOLLink(shipmentData.billOfLadingType, shipmentData.billOfLadingValue)
  }
  /* Set initialization values if this is new
   * -- Try to remove these eventually....
   */
  if (isNew) {
    shipmentData.activation = {}
    shipmentData.sliderStartDate = ''
    shipmentData.sliderEndDate = ''
    shipmentData.scanner = shpmnt.scanner
    shipmentData.lastEventUpdate = shpmnt.decommDate ? moment.utc(shpmnt.decommDate, dateFrmtStr) : moment.utc()
    shipmentData.tsData = []
    shipmentData.locData = []
    shipmentData.tiltShockData = []
    shipmentData.routeName = shpmnt.route || false,
    shipmentData.selected = false
  }
  return shipmentData
}

/**
  * Builds a single array with all activated alert states
  * @param {string} securityState - hex representation of security bitmask
  * @param {string} alertState - hex representation of alert bitmask
  * @param {string} onTimeState - hex representation of on time bitmask
  */
export const buildStateArr = (securityState, alertState, onTimeState) => {
  const stateArr = []
  const secState = parseInt(securityState, 10)
  if ((secState & 1) === 1) stateArr.push('201')
  if ((secState & 2) === 2) stateArr.push('218')
  if ((secState & 4) === 4) stateArr.push('208')
  if ((secState & 8) === 8) stateArr.push('204')
  if ((secState & 16) === 16) stateArr.push('222')
  const alrtState = parseInt(alertState, 10)
  if ((alrtState & 1) === 1) stateArr.push('211')
  if ((alrtState & 2) === 2) stateArr.push('216')
  if ((alrtState & 4) === 4) stateArr.push('205')
  if ((alrtState & 8) === 8) stateArr.push('17')
  if ((alrtState & 16) === 16) stateArr.push('18')
  if ((alrtState & 32) === 32) stateArr.push('221')
  if ((alrtState & 64) === 64) stateArr.push('220')
  if ((alrtState & 128) === 128) stateArr.push('223')
  const onTmState = parseInt(onTimeState, 10)
  if ((onTmState & 1) === 1) stateArr.push('3')
  if ((onTmState & 2) === 2) stateArr.push('206')
  if ((onTmState & 4) === 4) stateArr.push('207')
  if ((onTmState & 8) === 8) stateArr.push('22')
  if ((onTmState & 16) === 16) stateArr.push('209')
  return stateArr
}

/**
 * Calculates the centroid of a set of points using basic average of latitude and longitude
 * @param {Array} points - Array of point objects with format {lat: ###, lng: ###}
 * @returns {Object} centroid of the input points
 */
export const centroid = function (points) {
  if (points.length < 1) return {lat: 0.0, lng: 0.0}
  const center = points.reduce((x, y) => {
    return {lat: x.lat + y.lat / points.length, lng: x.lng + y.lng / points.length}
  }, {lat: 0.0, lng: 0.0})
  return center
}

/**
 * formatShockData - formats given messages API response to respectable shock data
 * format to store in the state with RMS, X, Y, Z and timestamp values
 * @param {Object} shockRsp - Object contains the shock data
 * @returns {Object} formatted shock data or empty object if given input is {}
 */
export const formatShockData = (shockRsp = {}) => {
  // if given input is an empty object then return it
  if (Object.values(shockRsp).length === 0) {
    return shockRsp
  }
  const shockData = {}
  /* RMS calculation */
  const rmsCalc = (x, y, z) => Math.sqrt((x * x) + (y * y) + (z * z))
  const rms = new Array(32).fill(0)
  const sampleRate = Number(shockRsp[0].SampRate)
  // 1000 * ( counter / (3200 / 2 ** (15- sampRate)) where counter = 0-31
  const xAxisIdx = [...rms]
  const axisData = xAxisIdx.map((val, idx) => 1000 * ( idx / (3200 / 2 ** (15 - sampleRate))))
  // shockData.timestamp = response['Device Time (UTC)']
  const mapFn = function (itm) {
    return [axisData[Number(itm[0].substring(3))], parseFloat(itm[1])]
  }
  for (const val of Object.values(shockRsp)) {
    const readings = Object.entries(val)
      .filter(itm => itm[0].match('REC'))
      .map(mapFn)
    shockData[val.Axis] = readings
    shockData.timestamp = val['Device Time (UTC)']
  }
  // rms.map(() => {})
  if (shockData.X && shockData.Y && shockData.Z) {
    shockData.RMS = rms.map((val, idx) => [axisData[idx], rmsCalc(shockData.X[idx][1], shockData.Y[idx][1], shockData.Z[idx][1])])
  }
  // if (shockData.Xf) {
  //   shockData.RMSf = rms.map((val, idx) => [idx, rmsCalc(shockData.Xf[idx][1], shockData.Yf[idx][1], shockData.Zf[idx][1])])
  // }
  return shockData
}

/**
 * Filter function to calculate whether a timestamp lies between two others
 * to allow filtering of input based on date range
 * @param {Array|Object} range - Point to be tested.
 * @param {datetime} reading - Point to be tested.
 * @returns {boolean} Indication of whether point is between the two oters.
 */
export const isBetween = (range) => (reading) => {
  const timeStamp = Array.isArray(reading) ? reading[0] : reading.timestamp
  /* const lower = moment(range[0], 'M-DD-YY').format('x')
  const upper = moment(range[1], 'M-DD-YY').endOf('day').format('x') */
  // return timeStamp >= lower && timeStamp <= upper
  return timeStamp >= range[0] && timeStamp <= range[1]
}

/**
 * Returns url for host application is running on.
 * - Determines if http or https
 * - Assigns port value if required
 * @param {string} [path = ''] - Page path including any query w/ params
 * @returns {string} full URL.
 */
export const getUrl = (path = '') => {
  let port = window.location.port !== '80' ? ':' + window.location.port : ''
  if (port === ':') port = ''
  const proto = window.location.protocol
  const host = window.location.hostname
  return proto + '//' + host + port + path
}

/**
 * Returns the device type configured for the shipment/asset page
 * @param {Object} state - Root state object
 * @param {Number} configType - shipment config type configured in the page
 * @returns {String} deviceType Configured in the configurations || tags
 */
export const getShpmntDeviceType = (state, configType) => {
  const shipmentConfigTypes = state.configuration.shipmentConfigTypes
  return shipmentConfigTypes[configType].device || 'tags'
}

/**
 * Calculates an array with 2 date (or time) strings starting with the given
 * date or today() if none is provided.
 * - Return date format M-DD-YY for example:
 *   - 5-15-17
 *   - 11-04-17
 * @param {number} [diff = 0] - Number of intervals of difference between start and end
 * @param {datetime} [end = null] - End date of range - will use today if set to null
 * @param {string} [scale = 'days'] - Type of data scale to use hours or days
 * @returns {Array} Array consisting of [start date/time, end date/time]
 */
export const getDate = (diff = 0, end = null, scale = 'days', tz = false) => {
  const dateVal = []
  const tmzn = tz || moment.tz.guess()
  if (Number.isInteger(diff)) {
    if (scale === 'hours') {
      // If using hours, always start one hour ahead and look back 'diff' hours
      dateVal.push(moment.utc().subtract(diff, 'hours').tz(tmzn).format('HH:mm'))
      dateVal.push(moment.utc().add(1, 'hours').tz(tmzn).format('HH:mm'))
    } else {
      // Default is days - use if days or anything elese
      if (end) {
        dateVal.push(moment(end).subtract(diff, 'days').format('M-DD-YY'))
        dateVal.push(moment(end).format('M-DD-YY'))
      } else {
        dateVal.push(moment().subtract(diff, 'days').format('M-DD-YY'))
        dateVal.push(moment().subtract(0, 'days').format('M-DD-YY'))
      }
    }
  }
  return dateVal
}

/**
 * Calculates an array with N date strings starting with the given
 * date or today() if none is provided.
 * - returns number of dates = argument 'count'
 * - date format M-DD-YY for example:
 *   - 5-15-17
 *   - 11-04-17
 * @param {number} [count = 30] - Number of days to include - will use 30 if set to null
 * @param {datetime} [end = null] - End date of range - will use today/now
 * @param {string} [scale = 'days'] - Type of data scale to use hours or days
 * @returns {Array} Array consisting of [start date, next date, ...,end date]
 */
export const getDateArr = (count = 30, end = null, scale = 'days', tz = false) => {
  const dates = []
  let offset = 0
  const tmzn = tz || moment.tz.guess()
  if (end) {
    offset = moment.utc().diff(end, 'days')
  }
  if (Number.isInteger(count)) {
    if (scale === 'hours') {
      // if hours, start 1 hour ahead and work backwards - do not use offset even if provided
      for (let i = -1; i <= count; i++) {
        dates.unshift(moment.utc().subtract(i, 'hours').tz(tmzn).format('HH:mm'))
      }
    } else {
      // if nothing is provided for scale, default to days
      for (let i = offset; i <= (count + offset); i++) {
        dates.unshift(moment.utc().subtract(i, 'days').format('M-DD-YY'))
      }
    }
  }
  return dates
}

/**
 * Calculates the value for map width based on input page size
 * - Default 45% - may be overridden
 * - Size for mobile/small screen 95%
 * @param {number} windowWidth - Integer value of current window width
 * @param {number} [percent = 0.45] - Percent of screen width for map to use
 * @returns {number} Calculated map width
 */
export const getMapWidth = (windowWidth, percent = 0.45) => {
  return windowWidth > 981 ? windowWidth * percent : windowWidth * 0.95
}

/**
 * Finds all message types from object by looking for pattery ##/##
 * @param {Object} messageObj - Object with keys that include pattern
 * @returns {Array} Array of message types ['##/#', '##/##'] Ex: ['60/1', '60/4', '48/4']
*/
export const getMessageTypes = (messageObj) => {
  if (!messageObj) return []
  return Object.values(messageObj).map(v => `${v.pkttype}/${v.msgtype}`)
}

/**
 * Calculates and returns the minimum and maximum for both latitude and
 * longitude for a set of points.
 * @param {Array} points - Array of points with format {lat: ###, lng: ###}
 * @returns {Array} Array consisting of [min lat, max lat, min lng, max lng].
 */
export const getMinMaxLatLng = (points) => {
  if (points.length < 1) return [0, 0, 0, 0]
  const minMax = points.reduce((x, y) => {
    return [Math.min(x[0], y.lat), Math.max(x[1], y.lat), Math.min(x[2], y.lng), Math.max(x[3], y.lng)]
  }, [90, -90, 180, -180])
  return minMax
}

/**
 * Calculates a single value which is the average of the input
 * rounded to the desired number of decimal places
 * @param {Array} [numArr = []] - Array of input. May be array of strings or
 * numbers (all one type)
 * @param {Array} [decPlaces = 0] - Number of digits to round value to.
 * @returns {number} Number of average.  Value is float if decimal count > 0 or
 * Int if decimal count = 0
 */
export const getRoundedAverage = (numArr = [], decPlaces = 0) => {
  if (numArr.length === 0) {
    return 0
  }
  if (toType(numArr[0]) === 'string') {
    numArr = numArr.map(x => Number.parseFloat(x))
  }
  const sum = numArr.reduce((x, y) => x + y)
  // numArr = numArr.map(x => Number.parseFloat(x))
  const average = round((sum / numArr.length), decPlaces)
  return average
}

/** Check if any roles are assigned for page<br>
 *  - if no return true<br>
 *  - if yes check users roles intersect required roles<br>
 * @param {Array} userRoles - List of roles assigned to user
 * @param {Array} pageRoles - List of allowed roles for page - empty indicates all roles allowed.
 * @returns {boolean} Indication of whether access is allowed.
 */
export const hasRole = function (userRoles, pageRoles = []) {
  if (pageRoles.length === 0) return true
  const intersect = pageRoles.filter((role) => {
    return userRoles.indexOf(role) !== -1
  })
  return intersect.length > 0
}

/**
 * Helper function to generate rectangle shape in custom type chart.<br>
 * Example include warehouse door charts
 * @param {object} params - parameters sent by chart (coordinates, etc.)
 * @param {object} api - api object sent by chart (values, etc.)
 * @returns {object} Rectangle definition.
 */
export const renderRectangle = (params, api) => {
  const categoryIndex = api.value(0)
  const start = api.coord([api.value(1), categoryIndex])
  const end = api.coord([api.value(2), categoryIndex])
  const height = api.size([0, 1])[1] * 0.7
  return {
    type: 'rect',
    shape: echarts.graphic.clipRectByRect({
      x: start[0],
      y: start[1] - height / 2,
      width: end[0] - start[0],
      height
    }, {
      x: params.coordSys.x,
      y: params.coordSys.y,
      width: params.coordSys.width,
      height: params.coordSys.height
    }),
    style: api.style()
  }
}

/**
 * Generates a svg tag for a circle with variable color and radius.<br>
 * Circle is centered in middle of image (instead of 0,0)
 * @param {string} [color = 'red'] - text or RGB color code of circle.
 * @param {number} [dims = 10] - Number of pixels for circle dimensions
 * (height & width)
 * @returns {string} SVG output that defines the image.
 */
export const svgCircle = (color = 'red', dims = 10) => {
  const ctr = Math.trunc(dims / 2)
  return `<svg height="${dims}" width="${dims}"><circle cx="${ctr}" cy="${ctr}" r="${ctr}" fill="${color}" /></svg>`
}
/**
 * Creates a SVG Icon
 * @param {object} state
 * @param {object} icon - value of generate icon
 */
export const generateIconSVG = (state, icon) => {
  const size = icon.size || state.fontSVG.defaultIcon.size
  // Check the given color code is a hex value
  // If it is hex then use it. Else check the color configuration for the given color
  // If color config not found, then use the default color from config
  const color = icon.color && icon.color.match('^#[a-fA-F0-9]{6}$') ? icon.color : state.fontSVG.iconColors[icon.color] || state.fontSVG.defaultIcon.color
  const iconname = icon.name || state.fontSVG.defaultIcon.name

  const pathsrc = state.fontSVG.iconSVGs[iconname]

  const svgText = `<svg viewBox="0 0 300 300" width="${size}" fill="${color}" height="${size}">${pathsrc}</svg>`
  return svgText
}

/**
 *
 * @param {Array} header - Given header list
 * @param {Array} data - Given data/rows list
 * @param {String} fileName - Filename to download
 * Download the csv file with given data and file name
 */
export const exportAsCSV = (header, data, fileName = 'ouput.csv') => {
  let csv = `${header.join(',')}\n`
  csv = csv + data.join('\n')
  if (window.navigator.msSaveBlob) { // Download script for IE
    const blob = new Blob([decodeURIComponent('%ef%bb%bf') + csv], {type: 'text/csv;charset=utf-8'})
    window.navigator.msSaveBlob(blob, fileName)
  } else {
    const hiddenElement = document.createElement('a')
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
    hiddenElement.target = '_blank'
    hiddenElement.download = fileName
    document.getElementById('downloadCsvSection').appendChild(hiddenElement) // For Firefox, need to append a <a> element to downloadCsvSection div
    hiddenElement.click()
  }
}

/**
 *
 * @param {Object} state - State Object
 * @param {Object} payloadObj - Input payload
 * @returns - Returns object with activation values like msg, comm, showDelete values
 */
// skipcq: JS-0045.  Allow Inconsistent return values of function
// skipcq: JS-0044.  Allow Cyclomatic complexity more than allowed
export const getActivation = (state, payloadObj) => {
  const status = payloadObj.saveStatus
  const replacerObj = {status}
  const dateFrmtStr = 'ddd MMM DD HH:mm:ss YYYY'
  let msg = '-'
  const classValue = state.configuration.shipmentConfigTypes[payloadObj.configType].class
  if (payloadObj.shippingState) {
    // If the transitMode = Shipment, then get the status from the Shipment config
    if (classValue === 'Shipment') msg = state.stateDefs.shipping[payloadObj.shippingState] || ''
    // If the transitMode = asset, then get the status from the asset config
    if (classValue === 'Asset') msg = state.stateDefs.asset[payloadObj.shippingState] || ''
    // If the classValue/aconfigType = vehicle, then get the status from the vehicle config
    if (classValue === 'Vehicle') msg = state.stateDefs.vehicle[payloadObj.shippingState] || ''
  }
  // Default output object
  const output = {
    comm: 'none',
    // msg: state.stateDefs.shipping[payloadObj.shippingState],
    msg,
    save: (status === 'OK' || status === '') ? 'good' : 'error',
    showDelete: false,
    showRebuild: false
  }
  // If enabled > 1 this is an error - return 'empty' result...
  if (Number(payloadObj.enabled) > 1) {
    output.save = 'none'
    return output
  }

  // If shipping state >=3 the shipment or asset has departed.  The automatic delete should not be provided.
  if (Number(payloadObj.shippingState) >= 3) {
    output.comm = 'good'
    // output.msg = 'Commissioned'
    return output
  }

  const deleteThreshold = state.configuration.shipmentConfigTypes[payloadObj.configType].deleteThreshold || 3
  // Get the delete threshold value form the shipment config types
  // If the shipping state is < the configured delete threshold value
  // Then show the delete option
  // output.showDelete = true
  if (Number(payloadObj.shippingState) < deleteThreshold) output.showDelete = true

  // If enabled = 1 the item is only saved.
  // Show delete and the returned save message.
  if (Number(payloadObj.enabled) === 1) {
    /* output.msg = payloadObj.saveStatus === 'OK' ?
    '<span style="color:green;">Ready to Commission.<span>' :
    `<span style="color:#930F0F;font-weight:bold;">${payloadObj.saveStatus}<span>` */
    const key = payloadObj.saveStatus === 'OK' ? 'getActSave' : 'getActSaveError'
    // skipcq: JS-0129.  Allow variable to used before definition
    output.msg = getMessageObject(state, 'activation', key, replacerObj).message
    return output
  }

  //If commState <= 1 the commissioning is still in progress.
  if (Number(payloadObj.commissionState) < 1) {
    output.comm = 'working'
    // output.msg = 'Commissioning'
    // skipcq: JS-0129.  Allow variable to used before definition
    output.msg = getMessageObject(state, 'activation', 'getActCommissioning', replacerObj).message
    return output
  }
  // Remaining conditions are shipments/assets that have commissioned but not fully built, departed etc.
  // These items are treated differently depending on the presence / absence of an ACL. = 0 If not shipment w/ ACL -
  //  For asset/vehicle/shipment w/o ACL - Good once commissioned....
  if ( Number(payloadObj.aclScanned) === 0 || Number(payloadObj.aclActual) === Number(payloadObj.aclExpected)) {
    output.comm = 'good'
    // output.msg = 'Commissioned'
    // skipcq: JS-0129.  Allow variable to used before definition
    output.msg = getMessageObject(state, 'activation', 'getActCommissioned', replacerObj).message
    return output
  }

  // Shipment with ACL...
  // show rebuild if expected != actual AND rebuild time > delay
  // show building network if expected != actual and rebuild < delay
  if (Number(payloadObj.aclActual) !== Number(payloadObj.aclExpected)) {
    const rebuildTime = payloadObj.lastStageCmdTime ? moment.utc(payloadObj.lastStageCmdTime, dateFrmtStr) : moment()
    const timeSinceRebuild = moment().diff(rebuildTime, 'minutes')
    if (timeSinceRebuild >= state.configuration.siteOptions.maxRebuildTime) {
      output.comm = 'warn'
      const refreshIcon = generateIconSVG(state, {name: 'refresh', color: 'red'})
      // output.msg = `<span style="color:#930F0F;font-weight:bold;">Click ${refreshIcon} to rebuild</span>`
      // skipcq: JS-0129.  Allow variable to used before definition
      output.msg = getMessageObject(state, 'activation', 'getActRebuild', {...replacerObj, refreshIcon}).message
      output.showRebuild = true
      return output
    }
    // getActBuilding
    output.comm = 'working'
    // skipcq: JS-0129.  Allow variable to used before definition
    output.msg = getMessageObject(state, 'activation', 'getActBuilding', {replacerObj}).message
    return output
  }
  // `<span style="color:#930F0F;font-weight:bold;">Delete shipment using ${generateIconSVG(rootState, {name: 'trash', color: 'red'})} and redo using a different iTag.<span>`
}

/**
 * Get the shipment destination details using the project ID
 * @param {Object} store - Vuex State Object
 * @param {String} projectId - Project ID
 * @returns
 */
export const lookupProjectLocationHelper = (store, projectId) => {
  // Get the key value from configuration
  const key = store.state.configuration.apiKey
  const promise = new Promise((resolve, reject) => {
    intelytApiProvider(store.state, `project?key=${key}&p=${projectId}`).then(response => {
      resolve(response.data)
    }).catch(e => {
      let message = ''
      if (e.response.data && e.response.data.responseMessage) {
        message = e.response.data.responseMessage
      } else if (!e.response.data && e.message) {
        message = e.message
      }
      const rejectResp = {message}
      reject(rejectResp)
    })
  })
  return promise
}

/**
 * Helper function to lookup the project id using the crate and tote ids
 * @param {object} store - Vuex store
 * @param {object} args - Shipment object for the selected shipment id
 * @returns {promise} Returns the promise as the response.
 */
export const lookupProjectIdHelper = (store, args) => {
  const crateId = args.row.customer || ''
  const toteId = args.row.notes || ''
  const guid = args.row.guid
  const entryIdSPL = args.row.entryIdSPL
  // const key = '1$6Fgs4A!'
  // Get the key value from configuration
  const key = store.state.configuration.apiKey
  const promise = new Promise((resolve, reject) => {
    intelytApiProvider(store.state, `project?key=${key}&c=${crateId}&t=${toteId}`).then(response => {
      // Set default retval if no project id returned
      let retVal = {'status': 404, 'message': `No data returned for Crate/Tote: ${crateId} ${toteId}`}
      // Lookup project id
      const projId = response.data.projectId || ''
      const crateCode = response.data.crateId || ''
      const crateNo = response.data.crateNo || 0
      if (projId) {
        // project Id found...update database and state and return success....
        // let msg = `Project ID loaded succesfully: ${projId}`
        const updateData = {guid, entryId: entryIdSPL, customData: projId}
        if (parseInt(crateNo) > 0) updateData.stateInt1 = parseInt(crateNo)
        if (crateCode.length > 0) {
          updateData.customData2 = crateId
          // msg += `<br>Crate ID updated successfully: ${crateCode}`
        }
        store.dispatch('shipments/editShipment', updateData)
        // retVal = {'status': 200, 'message': msg}
        retVal = {'status': 200}
      }
      resolve(retVal)
    }).catch(e => {
      let message = ''
      if (e.response.data && e.response.data.responseMessage) {
        message = e.response.data.responseMessage
      } else if (!e.response.data && e.message) {
        message = e.message
      }
      const rejectResp = {message}
      reject(rejectResp)
    })
  })
  return promise
}

/**
 *
 * @param {Object} shpmnt - Input shipment object
 * @returns {Object} - Returns the message object payload
 */
export const lookupProjectMsgHelper = (shpmnt) => {
  let devType = shpmnt.deviceType || ''
  // Capitalize the first letter
  if (devType) devType = devType.charAt(0).toUpperCase() + devType.slice(1)
  const crateId = shpmnt.customer || ''
  const toteId = shpmnt.notes || ''
  let assetType = 'Crate'
  let assetId = crateId
  if (toteId.length > 4) {
    assetType = 'Tote'
    assetId = toteId
  }
  return {assetId, assetType, devType}
}

/**
 * ssoLogin - Implements the single sign on request using the
 * onelogin provider.
 * @param {String} client - Client ID to get the client configurations
 * Finally redirects to the end point
 */
 export const ssoLogin = (clientConfig) => {
  // Get the client configurations
  // const {path, params} = JSON.parse(folwObj)
  // Get the callback path and request params
  const redirectUri = getUrl('/login')
  clientConfig.redirect_uri = redirectUri
  const path = clientConfig.accessCodeRequestPath
  // Redirect path construction
  // let requestPath = `${path}?client_id=${clientId}&response_type=id_token&nonce=test&scope=openid profile params&redirect_uri=${callbackPath}`
  let requestPath = `${path}?`
  // Loop through the param item configured and assign the value to the params
  clientConfig.accessCodeRequestParams.forEach(param => {
    let paramValue = ''
    if (Object.prototype.hasOwnProperty.call(clientConfig, param)) paramValue = clientConfig[param]
    requestPath = `${requestPath}${param}=${paramValue}&`
  })
  // Remove the trailing end & in the request path
  if (requestPath.slice(-1) === '&') requestPath.substring(0, requestPath.length - 1)
  // Redirect to the sso provider application
  window.location.assign(requestPath)
}

/**
 * Parse the given string value and replace the matched string value
 * using the given payload object
 * @param {String} expression - Input string value
 * @param {Object} valueObj - Object that contains the replacement value
 * @returns {String} - Parsed string value
 */
export const stringTemplateParser = (expression, valueObj) => {
  const templateMatcher = /{{\s?([^{}\s]*)\s?}}/g
  const text = expression.replace(templateMatcher, (substring, value) => {
    return valueObj[value]
  })
  return text
}

/**
 * Replace the token values contains in the params object
 * @param {Array/Object} params - Params that contains replacable token parameters
 * @param {*} srcObj - Source object that contains the values
 * @returns - Returns the final output as JSON with token values replaced
 */
export const getDynamicQueryParams = (params, srcObj) => {
  const paramString = JSON.stringify(params)
  return JSON.parse(stringTemplateParser(paramString, srcObj))
}

/**
 * @param {Object} stateObj - Vuex state object
 * @param {String} pageName - Current page name
 * @param {String} keyName - To get the sub section config for the given page name
 * @param {replacerObj} - Object that contains the replacer values
 * @returns {Object} - Message object that contains title and message values
 */
export const getMessageObject = (stateObj, pageName, keyName, replacerObj = {}) => {
  let messageObj = {title: '', message: ''}
  // If the data found for the given page name And also for the given key name
  // Then process the data
  if (stateObj.messages[pageName] && stateObj.messages[pageName][keyName]) {
    const messageConfig = stateObj.messages[pageName][keyName]
    const title = messageConfig.title ? stringTemplateParser(messageConfig.title, replacerObj) : ''
    const message = stringTemplateParser(messageConfig.message, replacerObj)
    messageObj = {title, message}
    // If the messageConfig object have success msg attribute, then add it to the response
    if (Object.prototype.hasOwnProperty.call(messageConfig, 'success')) messageObj.success = stringTemplateParser(messageConfig.success, replacerObj)
    // If the messageConfig object have error msg attribute, then add it to the response
    if (Object.prototype.hasOwnProperty.call(messageConfig, 'error')) messageObj.error = stringTemplateParser(messageConfig.error, replacerObj)
  }
  return messageObj
}

export const getACLCommands = (macIdList = []) => {
  // Function to replace mac id's into command formats
  const mapFn = function (dev) {
    return `0x${dev.split('_').join(' 0x')}`
  }
  // Prepare the dynamic command for the given device chunks
  const mapCommand = function (chunckedList, idx) {
    const devListString = chunckedList.join(' ')
    return `123 "S" ${idx} ${chunckedList.length} ${devListString}`
  }
  // Split the given device list to required chunk list
  const getChunkedDevList = function (devList, chunkSize) {
    const res = []
    for (let i = 0; i < devList.length; i += chunkSize) {
        const chunk = devList.slice(i, i + chunkSize)
        res.push(chunk)
    }
    return res
  }
  // Convert the mac ids into command format
  const devList = macIdList.map(mapFn)
  // Return the chunked chime list commands
  return getChunkedDevList(devList, 8).map(mapCommand)
}

export const getCommandList = (commands = [], replacerObj = {}) => {

  // Merge the list to reduce the stringTemplateParser function call
  // Then split it later
  const commandList = stringTemplateParser(commands.join(','), replacerObj).replace(/'/g, '"').split(',')
  return commandList
}

/**
 * Return the date format selected by the user
 * @param {String} type - Type of the format to be returned long/short
 * @returns {String} - Returns the date format chosen by the user
 */
export const getDateFormat = (type = 'long') => {
  // Get the available format
  const format = localStorage.getItem('dateFormat')
  if (!format) return ''
  // Format type to be returned base on the payload
  const FrmtIdx = type === 'long' ? 0 : 1
  // console.debug(format.split('|')[FrmtIdx])
  return format.split('|')[FrmtIdx]
}